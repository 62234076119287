
import 'cross-fetch/polyfill'; // TODO pi3upgrade

import enums from './enums';


async function submitContactFormPublic(formData) {
  try {
    console.log('[Ajax] Sending public contact form...');
    let url = `/api/v3/contact-us`;
    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(formData),
    });
    if (!result.ok) {
      console.error('[Ajax] Error submitting public contact form.', formData);
      throw result;
    }
    let json = await result.json();
    return json;
  } catch(e) {
    console.error('[Ajax] Error submitting public contact form.', e);
    throw e;
  }
}

async function submitContactFormTeams(formData) {
  try {
    console.log('[Ajax] Sending teams contact form...');
    let url = `/api/v3/contact-us-teams`;
    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(formData),
    });
    if (!result.ok) {
      console.error('[Ajax] Error submitting teams contact form.', formData);
      throw result;
    }
    let json = await result.json();
    return json;
  } catch(e) {
    console.error('[Ajax] Error submitting teams contact form.', e);
    throw e;
  }
}


async function submitContactFormLearners(formData) { 
  try {
    console.log('[Ajax] Sending learners contact form...');
    let url = `/api/v3/help`;
    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(formData),
    });
    if (!result.ok) {
      console.error('[Ajax] Error submitting learners contact form.', formData);
      throw result;
    }
    let json = await result.json();
    return json;
  } catch(e) {
    console.error('[Ajax] Error submitting learners contact form.', e);
    throw e;
  }
}


async function loginSendEmail(email) {
  try {
    console.log('[Ajax] Sending login email...');
    let url = `/api/v3/login-email`;
    let response = await fetch(url, {
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ email: email }),
    });
    if (!response.ok) {
      console.error('[Ajax] Error sending login email.', response);
      throw response;
    }
    let json = await response.json();
    console.log('[Ajax] Email for login sent.', json);
    return json;

  } catch(e) {
    console.error('[Ajax] Error sending login email.', e);
    throw e;
  }
}


async function login(email, confirmationCode, remember) {
  try {
    console.log('[Ajax] Attempting login...');
    let url = `/api/v3/login`;
    let data = { email, confirmationCode, remember };
    let response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      console.error('[Ajax] Error attempting login.', data);
      throw response;
    }
    let json = await response.json();
    // console.log('[Ajax] Login completed.', json);
    return json;

  } catch(e) {
    console.error('[Ajax] Error attempting login.', e);
    throw e;
  }
}


async function logout() {
  try {
    console.log('[Ajax] Attempting logout...');
    let url = `/api/v3/logout`;
    let response = await fetch(url, {
      headers: {'Accept': 'application/json'},
      method: 'GET',
    });
    if (!response.ok) {
      console.error('[Ajax] Error attempting logout.');
      throw response;
    }
    let json = await response.json();
    console.log('[Ajax] logout completed.', json);
    return json;

  } catch(e) {
    console.error('[Ajax] Error attempting logout.', e);
    throw e;
  }
}


async function getUserInfo() {
  try {
    console.log('[Ajax] Fetching user info...');
    // do fetch here
    let url = `/api/v3/user`;
    let data = await fetch(url, {
      headers: {
        'Accept': 'application/json',
      },
      method: 'GET',
    });
    if (!data.ok) {
      console.error('[Ajax] Error fetching user info.', data);
      throw data;
    }
    let json = await data.json();
    // console.log('[Ajax] Fetched user info.', json);
    return json;
  } catch(e) {
    console.error('[Ajax] Error fetching user info.', e);
    throw e;
  }
}

async function updateUserProfile(data) {
  try {
    console.log('[Ajax] Updating user profile...');

    let url = `/api/v3/user`;
    let response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      console.error('[Ajax] Error updating user profile.', data);
      throw response;
    }
    let json = await response.json();
    // console.log('[Ajax] Updated user profile.', json);
    return json;


  } catch(e) {
    console.error('[Ajax] Error updating user profile.', e);
    throw e;
  }
}


async function getCourses() {
  try {
    console.log('[Ajax] Fetching courses...');
    let url = `/api/v3/courses`;
    let data = await fetch(url, {
      headers: {
        'Accept': 'application/json',
      },
      method: 'GET',
    });
    if (!data.ok) {
      console.error('[Ajax] Error fetching courses.', data);
      throw data;
    }
    let json = await data.json();
    // console.log('[Ajax] Fetched courses.', json);
    return json;
  }
  catch(e) {
    console.error('[Ajax] Error fetching courses.', e);
    throw e;
  }
}


async function getCourse(courseId) {
  try {
    console.log(`[Ajax] Fetching course... courseId: ${courseId}`);
    let url = `/api/v3/course/${courseId}`;
    let data = await fetch(url, {
      headers: {
        'Accept': 'application/json',
      },
      method: 'GET',
    });
    if (!data.ok) {
      console.error('[Ajax] Error fetching course.', data);
      throw data;
    }
    let json = await data.json();
    // console.log('[Ajax] Fetched course.', json);
    return json;
  }
  catch(e) {
    console.error('[Ajax] Error fetching course.', e);
    throw e;
  }
}


async function getCourseProgress(courseId) {
  try {
    console.log(`[Ajax] Fetching course progress... courseId: ${courseId}`);
    let url = `/api/v3/course/${courseId}/progress`;
    let data = await fetch(url, {
      headers: {
        'Accept': 'application/json',
      },
      method: 'GET',
    });
    if (!data.ok) {
      console.error('[Ajax] Error fetching course progress.', data);
      throw data;
    }
    let json = await data.json();
    // console.log('[Ajax] Fetched course progress.', json);
    return json;
  }
  catch(e) {
    console.error('[Ajax] Error fetching course progress.', e);
    throw e;
  }
}


async function getLesson(lessonId) {
  try {
    console.log(`[Ajax] Fetching Lesson... lessonId: ${lessonId}`);
    let courseId = 0;
    let url = `/api/v3/course/${courseId}/lesson/${lessonId}`;
    let data = await fetch(url, {
      headers: {
        'Accept': 'application/json',
      },
      method: 'GET',
    });
    if (!data.ok) {
      console.error('[Ajax] Error fetching Lesson.', data);
      throw data;
    }
    let json = await data.json();
    // console.log('[Ajax] Fetched Lesson.', json);
    return json;
  }
  catch(e) {
    console.error('[Ajax] Error fetching Lesson.', e);
    throw e;
  }
}


async function postLearnerActivity(type, courseLessonId, quizTotal, quizCorrect, details) {
  try {
    console.log('[Ajax] Posting activity...', { type, courseLessonId });

    let url = `/api/v3/activity`;

    let data = {};
    if (type  === enums.UserActivity.Type.LessonView ||
        type === enums.UserActivity.Type.LessonComplete) {
      data = { type, courseLessonId };
    }

    else if (type === enums.UserActivity.Type.QuizComplete) {
      data = { type, courseLessonId, quizCorrect, quizTotal };
    }

    else if (type === enums.UserActivity.Type.ChatflowComplete) {
      data = { type, courseLessonId, quizCorrect, quizTotal };
    }

    if (details)
      data.details = details;

    let response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      console.error('[Ajax] Error posting activity.', data);
      throw response;
    }
    let json = await response.json();
    // console.log('[Ajax] Posted activity.', json);
    return json;

  } 
  catch(e) {
    console.error('[Ajax] Error posting activity.', e);
    throw e;
  }
}

async function getCertificates() {
  try {
    console.log('[Ajax] Fetching certificates...');
    let url = `/api/v3/certificates`;
    let data = await fetch(url, {
      headers: {
        'Accept': 'application/json',
      },
      method: 'GET',
    });
    if (!data.ok) {
      console.error('[Ajax] Error fetching certificates.', data);
      throw data;
    }
    let json = await data.json();
    // console.log('[Ajax] Fetched certificates.', json);
    return json;
  }
  catch(e) {
    console.error('[Ajax] Error fetching certificates.', e);
    throw e;
  }
}

async function generateCertificate(courseId) { 
  try {
    console.log('[Ajax] Generating certificate...', { courseId });

    let url = `/api/v3/certificate`;
    let data = { courseId };

    let response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      console.error('[Ajax] Error generating certificate.', data);
      throw response;
    }
    let json = await response.json();
    return json;

  }
  catch(e) {
    console.error('[Ajax] Error generating certificate.', e);
    throw e;
  }
}


async function getLicenses() {
  try {
    console.log('[Ajax] Fetching licenses...');
    let url = `/api/v3/licenses`;
    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET'
    });
    if (!result.ok) {
      console.error('[Ajax] Error fetching licenses.', result);
      throw result;
    }
    let json = await result.json();
    return json;
  }
  catch(e) {
    console.error('[Ajax] Error fetching licenses.', e);
    throw e;
  }
}

async function signLicense(licenseId) {
  try {
    console.log('[Ajax] Signing license...', { licenseId });
    let url = `/api/v3/license/${licenseId}/sign`;
    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: '{}',
    });
    if (!result.ok) {
      console.error('[Ajax] Error signing license.', licenseId);
      throw result;
    }
    let json = await result.json();
    return json;

  }
  catch(e) {
    console.error('[Ajax] Error signing license.', e);
    throw e;
  }
}

async function formLoad(fillAuthToken) {
  try {
    console.log(`Loading form...`, { fillAuthToken });

    let url = `/api/v3/form-load-public?fillAuthToken=${encodeURIComponent(fillAuthToken)}`;

    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    if (!result.ok) {
      console.error('[Ajax] Error loading form.', fillAuthToken);
      throw result;
    }
    let json = await result.json();
    return json;

  }
  catch(e) {
    console.error('[Ajax] Error in formLoad()', { fillAuthToken, e });
    throw e;
  }
}

async function formFill(fillAuthToken, data, metadata) {
  try {
    console.log(`Filling form...`, { fillAuthToken, data, metadata });

    let url = `/api/v3/form-fill-submit?fillAuthToken=${encodeURIComponent(fillAuthToken)}`;

    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        data: data,
        metadata: metadata,
      }),
    });
    if (!result.ok) {
      console.error('[Ajax] Error filling form.', fillAuthToken);
      throw result;
    }
    let json = await result.json();
    return json;

  }
  catch(e) {
    console.error('[Ajax] Error in formFill()', { fillAuthToken, e });
    throw e;
  }
}

async function formRecords(viewResultsAuthToken) {
  try {
    console.log(`Loading form...`, { viewResultsAuthToken });

    let url = `/api/v3/form-records?viewResultsAuthToken=${encodeURIComponent(viewResultsAuthToken)}`;

    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    if (!result.ok) {
      console.error('[Ajax] Error loading form.', viewResultsAuthToken);
      throw result;
    }
    let json = await result.json();
    return json;

  }
  catch(e) {
    console.error('[Ajax] Error in formLoad()', { viewResultsAuthToken, e });
    throw e;
  }
}

async function getProducts() { // DEPRECATED
  try {
    console.log(`[Ajax] Fetching Products...`);
    let url = `/api/v3/checkout/products`;
    let data = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });

    if (!data.ok) {
      console.error('[Ajax] Error fetching Products.', data);
      return;
    }
    let json = await data.json();
    // console.log('Fetched products.', json.length);
    return json;

  } catch(e) {
    console.error('[Ajax] Error fetching Products.', e);
    throw e;
  }
}


async function getProductsTeams() {
  try {
    console.log(`[Ajax] Fetching Products for Teams...`);
    let url = `/api/v3/checkout/products-teams`;
    let data = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });

    if (!data.ok) {
      console.error('[Ajax] Error fetching Products for Teams.', data);
      return;
    }
    let json = await data.json();
    // console.log('Fetched products for teams.', json.length);
    return json;

  } catch(e) {
    console.error('[Ajax] Error fetching Products for Teams.', e);
    throw e;
  }
}


async function getCheckoutSession(stripeCheckoutSessionId) {
  try {
    console.log(`[Ajax] Fetching Stripe Checkout Session...`, stripeCheckoutSessionId);
    let url = `/api/v3/checkout/session/${stripeCheckoutSessionId}`;
    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    if (!result.ok) {
      console.error('[Ajax] Error fetching Stripe Checkout Session .', result);
      throw result;
    }
    let json = await result.json();
    return json;
  }
  catch(e) {
    console.error('[Ajax] Error fetching Stripe Checkout Session.', { e });
    throw e;
  }
}


async function validateCoupon(couponCode) {
  try {
    console.log(`[Ajax] Validating coupon code...`, couponCode);
    let url = `/api/v3/checkout/validate-coupon`;

    let formData = { couponCode };

    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(formData),
    });
    if (!result.ok) {
      console.error('[Ajax] Error validating coupon code.', formData);
      throw result;
    }
    let json = await result.json();
    return json;
  } catch(e) {
    console.error('[Ajax] Error validating coupon code.', { couponCode, e });
    throw e;
  }
}

async function validateEmail(email) {
  try {
    console.log(`[Ajax] Validating new user email...`, email);
    let url = `/api/v3/checkout/validate-email`;

    let formData = { email };

    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(formData),
    });
    if (!result.ok) {
      console.error('[Ajax] Error validating new user email.', formData);
      throw result;
    }
    let json = await result.json();
    return json;
  } catch(e) {
    console.error('[Ajax] Error validating new user email.', { email, e });
    throw e;
  }
}

async function checkout(formData) {
  try {
    console.log(`[Ajax] Submitting checkout form...`, formData);
    let url = `/api/v3/checkout`;
    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(formData),
    });
    if (!result.ok) {
      console.error('[Ajax] Error submitting checkout form.', formData);
      throw result;
    }
    let json = await result.json();
    return json;
  } catch(e) {
    console.error('[Ajax] Error submitting checkout form.', { formData, e });
    throw e;
  }
}

async function repurchase(formData) { // checkout-v4 TODO rename
  try {
    console.log(`[Ajax] Submitting repurchase form...`, formData);
    let url = `/api/v4/purchase/repurchase`;
    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(formData),
    });
    if (!result.ok) {
      console.error('[Ajax] Error submitting repurchase form.', formData);
      throw result;
    }
    let json = await result.json();
    return json;
  } catch(e) {
    console.error('[Ajax] Error submitting repurchase form.', { formData, e });
    throw e;
  }
}

async function getProductsV4() { // checkout-v4 TODO rename
  try {
    console.log(`[Ajax] Fetching Products (v4)...`);
    let url = `/api/v4/purchase/products`;
    let data = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });

    if (!data.ok) {
      console.error('[Ajax] Error fetching Products.', data);
      return;
    }
    let json = await data.json();
    // console.log('Fetched products.', json.length);
    return json;

  }
  catch(e) {
    console.error('[Ajax] Error in getProductsV4().', e);
  }
}

// checkout-v4
async function purchaseReturn(formData) {
  try {
    console.log(`[Ajax] Submitting purchase/return...`, formData);
    let url = `/api/v4/purchase/return`;
    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(formData),
    });
    if (!result.ok) {
      console.error('[Ajax] Error submitting purchase/return.', formData);
      throw result;
    }
    let json = await result.json();
    return json;
  }
  catch(e) {
    console.error('[Ajax] Error submitting purchase/return.', e);
  }
}

// checkout-v4
async function purchaseNew(formData) {
  try {
    console.log(`[Ajax] Submitting purchase/new...`, formData);
    let url = `/api/v4/purchase/new`;
    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(formData),
    });
    if (!result.ok) {
      console.error('[Ajax] Error submitting purchase/new.', formData);
      throw result;
    }
    let json = await result.json();
    return json;
  }
  catch(e) {
    console.error('[Ajax] Error submitting purchase/new.', e);
  }
}


async function checkoutTeams(formData) {
  try {
    console.log(`Submitting checkout form for teams...`, formData);
    let url = `/api/v3/checkout/teams`;
    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(formData),
    });
    if (!result.ok) {
      console.error('[Ajax] Error submitting checkout form for teams.', formData);
      throw result;
    }
    let json = await result.json();
    return json;
  } catch(e) {
    console.error('[Ajax] Error submitting checkout form for teams.', { formData, e });
    throw e;
  }
}


async function getManagerCompany(companyId) {
  try {
    console.log(`Fetching Manager Company...`, { companyId });
    let url = `/api/v3/reporting/company/${companyId}`;
    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    if (!result.ok) {
      console.error('[Ajax] Error fetching Manager Company.', result);
      throw result;
    }
    let json = await result.json();
    return json;
  }
  catch(e) {
    console.error('[Ajax] Error in getManagerCompany()', { companyId, e });
    throw e;
  }
}

async function getManagerReport(companyPlanId) {
  try {
    console.log(`Fetching Manager Report...`, { companyPlanId });

    let url = `/api/v3/reporting/manager2?planId=${companyPlanId}`;
    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    if (!result.ok) {
      console.error('[Ajax] Error fetching Manager Report.', result);
      throw result;
    }
    let json = await result.json();
    return json;
  }
  catch(e) {
    console.error('[Ajax] Error in getManagerReport()', { companyPlanId, e });
    throw e;
  }
}

async function getJoinInfo(magicLink) {
  try {
    console.log(`Fetching Join info...`, { magicLink });

    let url = `/api/v3/join/${magicLink}`;
    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    if (!result.ok) {
      console.error('[Ajax] Error fetching Join info.', result);
      throw result;
    }
    let json = await result.json();
    return json;
  }
  catch(e) {
    console.error('[Ajax] Error in getJoinInfo()', { magicLink, e });
    throw e;
  }
}

async function joinCheck(magicLink, data) {
  try {
    console.log(`Checking if we should join team...`, { magicLink, data });
    let url = `/api/v3/join-check/${magicLink}`;
    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    });
    if (!result.ok) {
      console.error('[Ajax] Error pre-checking for joining team.', { magicLink, data });
      throw result;
    }
    let json = await result.json();
    return json;

  }
  catch(e) {
    console.error('[Ajax] Error in joinCheck()', { magicLink, e });
    throw e;
  }
}

async function joinTeam(magicLink, userData) {
  try {
    console.log(`Joining team...`, { magicLink, userData });

    let url = `/api/v3/join/${magicLink}`;

    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(userData),
    });
    if (!result.ok) {
      console.error('[Ajax] Error joining team.', userData);
      throw result;
    }
    let json = await result.json();
    return json;

  }
  catch(e) {
    console.error('[Ajax] Error in getJoinInfo()', { magicLink, e });
    throw e;
  }
}


async function getFileTwine(url) {
  try {
    console.log(`[Ajax] Fetching file...`, { url });

    let response = await fetch(url, {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      method: 'GET',
      // Setting response type to arraybuffer 
      responseType: 'arraybuffer'
    });

    if (!response.ok) {
      throw new Error('[Ajax] Network response was not OK.');
    }

    return response.text();
  }
  catch(e) {
    console.error('[Ajax] Error in getFile()', { url, e });
    throw e;
  }
}


export {
  submitContactFormPublic,
  submitContactFormTeams,
  submitContactFormLearners,
  loginSendEmail,
  login,
  logout,
  getUserInfo,
  updateUserProfile,
  getCourses,
  getCourse,
  getCourseProgress,
  getLesson,
  postLearnerActivity,
  getCertificates,
  generateCertificate,
  getLicenses,
  signLicense,

  formLoad,
  formFill,
  formRecords,

  // teams onboarding
  getJoinInfo,
  joinCheck,
  joinTeam,

  // purchase flow
  getProducts,
  getProductsTeams,
  getCheckoutSession,
  validateCoupon,
  validateEmail,
  checkout,
  checkoutTeams,
  repurchase, // checkout-v4
  getProductsV4, // checkout-v4
  purchaseReturn, // checkout-v4
  purchaseNew, // checkout-v4

  // manager reporting
  getManagerCompany,
  getManagerReport,

  // general
  getFileTwine,
};

