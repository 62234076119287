
import React from 'react';

import enums from './enums';


function getStatusIcon(status) {
  if (status === enums.CourseLesson.Status.Read) {
    return (
      <div className="icon-lesson-read"></div>
    );
  }
  else if (status === enums.CourseLesson.Status.Reading) {
    return (
      <div className="icon-lesson-reading"></div>
    );
  }
  else if (status === enums.CourseLesson.Status.Unread) {
    return (
      <div className="icon-lesson-unread"></div>
    );
  }
  else return '';
}


function MenuCourseProgress(props) {

  let progress = props.progress;
  let course = props.course;

  return (

    <div className='pi3menuCourseStatus'>

      <span className='pi3menuCourseStatusBackground' style={{ width: `${progress.completedPercent}%` }}></span>

      <div className='pi3menuCourseStatusTexts'>

        <svg width="33" height="17" viewBox="0 0 33 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.4,7.8v7.4c0,.1,0,.2,0,.3,0,.1,0,.2-.2.3,0,0-.2.1-.2.2,0,0-.2,0-.3,0-.1,0-.2,0-.3,0,0,0-.2-.1-.2-.2,0,0-.1-.2-.2-.3,0,0,0-.2,0-.3v-7.5c0,0,0-.1,0-.2,0,0,0-.1,0-.2,0,0,0-.1-.2-.1,0,0-.1,0-.2,0-.6-.2-1.2-.4-1.9-.6-.2,0-.3-.1-.4-.3C0,6.1,0,6,0,5.8,0,5.4.2,5.2.6,5,5.6,3.4,10.6,1.7,15.6,0c.2,0,.5,0,.7,0,5,1.7,10,3.3,15,5,0,0,.2,0,.2.1.1,0,.3.2.4.3,0,.1.1.3.1.4,0,.2,0,.3-.2.4-.1.1-.3.2-.4.2l-14.9,5c-.3.1-.7.1-1,0-3.6-1.2-11.1-3.7-11.1-3.7Z" fill="white" />
          <path d="M9.9,11.2l5.3,1.8c.5.2,1,.2,1.5,0l8.4-2.8h.4c0-.1,0,4.9,0,4.9,0,0,0,.2,0,.3,0,0,0,.2-.1.2,0,0-.1.1-.2.2,0,0-.2,0-.3,0-2.9.9-5.7,1.8-8.6,2.7-.2,0-.5,0-.7,0-2.9-.9-5.7-1.8-8.6-2.6-.4-.1-.6-.3-.6-.7v-5.2l3.4,1.1Z" fill="white" />
        </svg>

        <h3>
          <a href={`/course/${course.id}/certificate`}>
            {progress.completedPercent}% Course Complete
          </a>
        </h3>

        <svg width="15" height="25" viewBox="0 0 15 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M0.903139 1.20294C1.8404 0.265685 3.35999 0.265685 4.29725 1.20294L13.8973 10.8029C14.8345 11.7402 14.8345 13.2598 13.8973 14.1971L4.29725 23.7971C3.35999 24.7343 1.8404 24.7343 0.903139 23.7971C-0.0341193 22.8598 -0.0341193 21.3402 0.903139 20.4029L8.80608 12.5L0.903139 4.59706C-0.0341193 3.6598 -0.0341193 2.1402 0.903139 1.20294Z" fill="white"/>
        </svg>

      </div>


    </div>


  );
}

function getLessonTypeIcon(type) {
  if (type === enums.CourseLesson.Type.Text) {
    return (
      <div className="icon-lesson-text"></div>
    );
  }
  else if (type === enums.CourseLesson.Type.Video) {
    return (
      <div className="icon-lesson-video"></div>
    );
  }
  else if (type === enums.CourseLesson.Type.Quiz) {
    return (
      <div className="icon-lesson-quiz"></div>
    );
  }
  else if (type === enums.CourseLesson.Type.QuizPlus) {
    return (
      <div className="icon-lesson-quiz"></div>
    );
  }
  else if (type === enums.CourseLesson.Type.Chatflow) {
    return (
      <div className="icon-lesson-chat"></div>
    );
  }
  else if (type === enums.CourseLesson.Type.Chat) {
    return (
      <div className="icon-lesson-chat"></div>
    );
  }
}

function getLessonTypeIconMonochrome(type) {
  if (type === enums.CourseLesson.Type.Text) {
    return (
      <div className="icon-lesson-text-monochrome"></div>
    );
  }
  else if (type === enums.CourseLesson.Type.Video) {
    return (
      <div className="icon-lesson-video-monochrome"></div>
    );
  }
  else if (type === enums.CourseLesson.Type.Quiz) {
    return (
      <div className="icon-lesson-quiz-monochrome"></div>
    );
  }
  else if (type === enums.CourseLesson.Type.QuizPlus) {
    return (
      <div className="icon-lesson-quiz-monochrome"></div>
    );
  }
  else if (type === enums.CourseLesson.Type.Chatflow) {
    return (
      <div className="icon-lesson-chat-monochrome"></div>
    );
  }
  else if (type === enums.CourseLesson.Type.Chat) {
    return (
      <div className="icon-lesson-chat-monochrome"></div>
    );
  }
}

function getNextLessonUrl(course, currentLessonId) {
  let lessons = course.sections.map(s => s.lessons.map(l => l)).flat();
  let lesson = lessons.find(l => l.id === currentLessonId);
  let currentLessonIndex = lessons.indexOf(lesson);

  let nextLessonUrl = `/course/${course.id}/certificate`;

  // if there is a next lesson, navigate to it
  if (currentLessonIndex + 1 < lessons.length) {
    let nextLesson = lessons[currentLessonIndex + 1];

    if (nextLesson.type === enums.CourseLesson.Type.Quiz) {
      nextLessonUrl = `/course/${course.id}/quiz/${nextLesson.id}`;
    }
    else if (nextLesson.type === enums.CourseLesson.Type.QuizPlus) {
      nextLessonUrl = `/course/${course.id}/quiz-plus/${nextLesson.id}`;
    }
    else if (nextLesson.type === enums.CourseLesson.Type.Chatflow) {
      nextLessonUrl = `/course/${course.id}/chatflow/${nextLesson.id}`;
    }
    else {
      nextLessonUrl = `/course/${course.id}/lesson/${nextLesson.id}`;
    }
  }

  return nextLessonUrl;
}


export {
  getStatusIcon,
  MenuCourseProgress,
  getLessonTypeIcon,
  getLessonTypeIconMonochrome,
  getNextLessonUrl,
};