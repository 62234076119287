
import ajaxAdmin from '../../src/pi3-frontend/ajax-admin.js';
import React from 'react';
import {css} from 'emotion';
import {useFirstRender} from '../../src/utilities/React.js';
import {useStateAtom} from '../../src/utilities/React/UseStateAtom.js';

export default function (props) {

  const forms = useStateAtom([]);

  useFirstRender(async () => {
    forms((await ajaxAdmin.adminFormList()).forms);
  });

  return (<>

    <div>
      {forms().map((form, formIndex) => (<>
        <div
          className={css`
            display: flex;
            width: 100%;
            border-style: solid;
            border-width: 1px;
            border-color: #d1d5db;
            gap: 16px;
            padding: 0 16px;
          `}
        >
          <div
            className={css`
              flex: 1 1 0%;
            `}
          >
            {form.name}
          </div>
          <div
            className={css`
              flex: none;
            `}
          >
            <a
              className={css`
                display: block;
                width: 100%;
                height: 100%;
                text-decoration: underline;
                cursor: pointer;
              `}
              onClick={async (e) => {
                await ajaxAdmin.adminFormCreate({
                  name: form.name + ' (Copy)',
                  definitionScript: form.definitionScript,
                });
                forms((await ajaxAdmin.adminFormList()).forms);
              }}
            >
              Duplicate
            </a>
          </div>
          <div
            className={css`
              flex: none;
            `}
          >
            <a
              href={`/admin/forms/${encodeURIComponent(form.id)}`}
              className={css`
                display: block;
                width: 100%;
                height: 100%;
              `}
            >
              Edit
            </a>
          </div>
          <div
            className={css`
              flex: none;
            `}
          >
            <a
              href={`/form-fill-quill?form=${encodeURIComponent(form.fillAuthToken)}`}
              className={css`
                display: block;
                width: 100%;
                height: 100%;
              `}
            >
              Fill
            </a>
          </div>
          <div
            className={css`
              flex: none;
            `}
          >
            <a
              href={`/admin/forms/${encodeURIComponent(form.id)}/records`}
              className={css`
                display: block;
                width: 100%;
                height: 100%;
              `}
            >
              Records
            </a>
          </div>
        </div>
      </>))}
    </div>

  </>);

};
