
import React from 'react';
import {useMemo} from 'react';

import {Form} from "@quillforms/renderer-core";
import {registerCoreBlocks} from "@quillforms/react-renderer-utils";
import "@quillforms/renderer-core/build-style/style.css";

registerCoreBlocks();

import {formDefinitionFillIds} from '../../src/helpers/formDefinitionFillIds.js';
import {formDefinitionToQuillFormDefinition} from '../../src/helpers/formDefinitionToQuillFormDefinition.js';
import {formFill} from '../../src/pi3-frontend/ajax.js';
import {formLoad} from '../../src/pi3-frontend/ajax.js';
import {registerBlockTypeHTML} from '../../src/pi3-frontend/helpers/quillforms/registerBlockTypeHTML.jsx';
import {registerBlockTypeOpinionScale} from '../../src/pi3-frontend/helpers/quillforms/registerBlockTypeOpinionScale.jsx';
import {useFirstRender} from '../../src/utilities/React.js';
import {useStateAtom} from '../../src/utilities/React/UseStateAtom.js';
import {yamlParse} from '../../src/utilities/yaml/yamlParse.js';

registerBlockTypeHTML();
registerBlockTypeOpinionScale();

export default function (props) {

  const formInfo = useStateAtom(null);
  const definitionAST = useStateAtom(null);

  const blocks = useMemo(
    () => {
      const map = {};
      for (const page of definitionAST()?.pages ?? [])
        for (const block of page.blocks) {
          map[block.id] = block;
          if (block.component == 'Group')
            for (const subBlock of block.blocks)
              map[subBlock.id] = subBlock;
        }
      return map;
    },
    [
      definitionAST(),
    ],
  );

  useFirstRender(async () => {
    formInfo(await formLoad((new URLSearchParams(window.location.search)).get('form')));
    definitionAST(formDefinitionFillIds(yamlParse(formInfo().definitionScript)));
  });

  return (<>

    <div style={{ width: "100%", height: "100vh" }}>
      {definitionAST() && (<>
        <Form
          formId="1"
          formObj={{
            theme: {
              buttonsBgColor: '#9a1638',
            },
            messages: {
              'label.submitBtn': 'Submit Survey',
              'block.defaultThankYouScreen.label': `
                <p>
                  <img src="/static/images/productinstitute-logo-color3.svg" alt="Product Institute" />
                </p>
                <p>
                  &nbsp;
                </p>
                <p>
                  <strong>Thank you for completing this survey!</strong>
                </p>
                <p>
                  &nbsp;
                </p>
                <p>
                  We will be aggregating the data and sharing with your leadership soon.
                </p>
                <p>
                  &nbsp;
                </p>
              `,
            },
            settings: {
              showQuestionsNumbers: false,
              showLettersOnAnswers: false,
            },
            customCSS: `
              /**
               * The following z-index tweaks are required to fix Quill forms
               * having a footed that is displayed over the dropdown options
               * making the dropdown options unselectable.
               */
              .renderer-core-fields-wrapper {
                z-index: 10000;
              }
              .renderer-core-block-stack-layout {
                z-index: 10001;
              }
            `,
            ...formDefinitionToQuillFormDefinition(definitionAST()),
          }}
          onSubmit={async (data, options) => {
            await formFill(
              (new URLSearchParams(window.location.search)).get('form'),
              {
                answers: Object.entries(data.answers).map(([k, v]) => ({
                  id: k,
                  value: v.value,
                  component: blocks[k]?.component ?? '',
                  props: blocks[k]?.props ?? {},
                  metadata: blocks[k]?.metadata ?? {},
                  quillFormsData: v,
                })),
              },
              {},
            );
            await new Promise(r => setTimeout(r, 500));
            options.setIsSubmitting(false);
            options.completeForm();
          }}
        />
      </>)}
    </div>

  </>);

};
