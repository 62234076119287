
import React from 'react';

import {registerBlockType} from '@quillforms/blocks';

/**
 * QuillForms Depndencies
 */
import {useMessages} from '@quillforms/renderer-core';
import {useTheme} from '@quillforms/renderer-core';

/**
 * React Dependencies
 */
import {useEffect} from 'react';

/**
 * External Dependencies
 */
import tinyColor from 'tinycolor2';
import {css} from 'emotion';
import classnames from 'classnames';

let timer;

// @see https://github.com/quillforms/quillforms/blob/master/react-docs/create-your-own-custom-block-type.md
// @see https://codesandbox.io/p/sandbox/quill-forms-custom-block-registration-xmbyiy?file=%2Fsrc%2Fmy-custom-block%2Fdisplay.js%3A142%2C1
export const registerBlockTypeOpinionScale = () => {
  registerBlockType('opinion-scale', {
    supports: {
      editable: true,
    },
    attributes: {
      start: {
        type: 'number',
        default: 1,
      },
      end: {
        type: 'number',
        default: 5,
      },
      leftLabel: {
        type: 'string',
        default: 'Strongly Disagree',
      },
      centerLabel: {
        type: 'string',
        default: 'Neutral',
      },
      rightLabel: {
        type: 'string',
        default: 'Strongly Agree',
      },
      reverseValue: {
        type: 'boolean',
        default: false,
      },
    },
    display: (props) => {
      const {
        id,
        attributes,
        setIsValid,
        setIsAnswered,
        setValidationErr,
        isActive,
        val,
        setVal,
        next
      } = props;

      const {required, start, end} = attributes;
      const messages = useMessages();
      const theme = useTheme();
      const answersColor = tinyColor(theme.answersColor);

      const checkfieldValidation = (value) => {
        if (required === true && (!value || value === '')) {
          setIsValid(false);
          setValidationErr(messages['label.errorAlert.required']);
        } else {
          setIsValid(true);
          setValidationErr(null);
        }
      };

      const items = [];
      for (let i = start; i <= end; i++) {
        if (!attributes.reverseValue)
          items.push(i);
        else
          items.unshift(i);
      }

      useEffect(() => {
        if (!isActive) {
          clearTimeout(timer);
        }
      }, [isActive]);

      useEffect(() => {
        checkfieldValidation(val);
      }, [val]);

      return (
        <>
          <div
            className={css`
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              height: 64px;
              margin-top: 15px;
              justify-content: space-between;
            `}
          >
            {items.map((item) => {
              return (
                <div
                  key={item}
                  className={classnames(
                    css`
                      height: 100%;
                      @media (min-width: 768px) {
                        flex: 1 1 0%;
                      }
                      @media (max-width: 767px) {
                        min-width: 50px;
                        flex-basis: calc(${100 / items.length}% - 6px);
                      }
                      font-size: 3rem;
                      max-width: 64px;
                      max-height: 64px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      outline: none;
                      cursor: pointer;
                      user-select: none;
                      margin-right: 6px;
                      margin-bottom: 10px;
                      border-radius: 4px;
                      background-color: ${answersColor.setAlpha(0.1).toString()};
                      color: ${answersColor.setAlpha(1).toString()};
                      box-shadow: ${answersColor.setAlpha(0.6).toString()} 0px 0px
                        0px 1px inset;
                      position: relative;
                      transition: all 0.1s ease-out 0s;
                      &:hover {
                        background: ${answersColor.setAlpha(0.2).toString()};
                      }
                      &:last-child {
                        margin-right: 0;
                      }
                      &.selected {
                        background: ${tinyColor(theme.answersColor)
                          .setAlpha(0.75)
                          .toString()};
                        color: ${tinyColor(theme.answersColor).isDark()
                          ? '#fff'
                          : '#333'};
                      }
                    `,
                    {selected: val === item},
                  )}
                  onClick={() => {
                    if (val !== item) {
                      setVal(item);
                      timer = setTimeout(() => {
                        setIsAnswered(true);
                        next();
                      }, 500);
                    } else {
                      clearTimeout(timer);
                      setIsAnswered(false);
                      setVal('');
                    }
                  }}
                >
                  {
                    !attributes.reverseValue
                      ? item
                      : (parseInt(attributes.end) - parseInt(item) + parseInt(attributes.start))
                  }
                </div>
              );
            })}
          </div>
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              justify-items: center;
              width: 100%;
            `}
          >
            <div
              className={css`
                flex-basis: 0;
                flex-grow: 1;
                text-align: left;
                font-size: 10px;
              `}
            >
              {attributes.leftLabel ?? ''}
            </div>
            <div
              className={css`
                flex-basis: 0;
                flex-grow: 1;
                text-align: center;
                font-size: 10px;
              `}
            >
              {attributes.centerLabel ?? ''}
            </div>
            <div
              className={css`
                flex-basis: 0;
                flex-grow: 1;
                text-align: right;
                font-size: 10px;
              `}
            >
              {attributes.rightLabel ?? ''}
            </div>
          </div>
        </>
      );
    },
  });
};
