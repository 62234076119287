import React from 'react';

import enums from './enums';
import { postLearnerActivity, getCourse, getCourseProgress } from './ajax';

import CourseBlockText from './CourseBlockText';
import CourseBlockVideo from './CourseBlockVideo';
import CourseBlockImage from './CourseBlockImage';
import CourseBlockYouTube from './CourseBlockYouTube';
import CourseBlockDocument from './CourseBlockDocument';
import CourseBlockCustomCode from './CourseBlockCustomCode';
import CourseBlockTwineChat from './CourseBlockTwineChat';
import CourseBlockChatflow from './CourseBlockChatflow';

import { getLessonTypeIconMonochrome, getNextLessonUrl } from './MenuTools';

class LearnerChatflow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      completeEnabled: false,
    };
    this.completeLessonAndNavigate = this.completeLessonAndNavigate.bind(this);
    this.postLessonView = this.postLessonView.bind(this);
  }

  async componentDidMount() {
    try {

      // load course for reasons (so we can 'next lesson')
      let course = await getCourse(this.props.lesson.section.course.id);
      this.setState({ course });

      const courseProgress = await getCourseProgress(this.props.lesson.section.course.id);
      const lessonProgress = courseProgress.lessons.find(l => l.lessonId == this.props.lesson.id);

      if (lessonProgress.isCompleted)
        this.setState({
          completeEnabled: true,
        });

      this.setState({
        courseProgress: courseProgress,
        lessonProgress: lessonProgress,
      });

      // after 1 minute send 'lesson view' activity
      setTimeout(() => {
        console.log('[LearnerChatflow] Posting lesson view...');
        this.postLessonView();
      }, 60 * 1000);

    }
    catch(e) {
      console.error('[LearnerChatflow] Error in componentDidMount()', e);
    }
  }

  async completeLessonAndNavigate() {
    try {

      let nextLessonUrl = getNextLessonUrl(this.state.course, this.props.lesson.id);

      // navigate to next lesson
      window.location.replace(nextLessonUrl);
      // console.log('replacing window lication?', nextLessonUrl); // debug
    }
    catch(e) {
      console.error('[LearnerChatflow] Error in completeLessonAndNavigate()', e);
    }
  }

  async postLessonView() {
    try {
      console.log('[LearnerChatflow] Posting lesson view...');
      let activity = await postLearnerActivity(
        enums.UserActivity.Type.LessonView, this.props.lesson.id);
    }
    catch(e) {
      console.error('[LearnerChatflow] Error in postLessonView()', e);
    }
  }

  render() {

    let lesson = this.props.lesson;
    let course = this.props.course;

    let blocks = [];
    for (let block of lesson.blocks) {

      if (block.type === enums.CourseBlock.Type.Text) {
        blocks.push(
          <CourseBlockText
            block={block}
            key={block.id}
          />
        );
      }

      else if (block.type === enums.CourseBlock.Type.Image) {
        blocks.push(
          <CourseBlockImage
            block={block}
            alt={lesson.name} // TODO fix for actual alt
            key={block.id}
          />
        );
      }

      else if (block.type === enums.CourseBlock.Type.Video) {
        blocks.push(
          <CourseBlockVideo
            block={block}
            key={block.id}
          />
        );
      }

      else if (block.type === enums.CourseBlock.Type.YouTube) {
        blocks.push(
          <CourseBlockYouTube
            block={block}
            alt={lesson.name} // TODO fix for actual alt
            key={block.id}
          />
        );
      }

      else if (block.type === enums.CourseBlock.Type.Document) {
        blocks.push(
          <CourseBlockDocument
            block={block}
            key={block.id}
          />
        );
      }

      else if (block.type === enums.CourseBlock.Type.CustomCode) {
        blocks.push(
          <CourseBlockCustomCode
            block={block}
            key={block.id}
          />
        );
      }

      else if (block.type === enums.CourseBlock.Type.TwineChat) {
        blocks.push(
          <CourseBlockTwineChat
            block={block}
            key={block.id}
            course={course}
          />
        );
      }

      else if (block.type === enums.CourseBlock.Type.Chatflow) {
        blocks.push(
          <CourseBlockChatflow
            block={block}
            lesson={lesson}
            lessonProgress={this.state.lessonProgress}
            key={block.id}
            onComplete={() => {
              this.setState({
                completeEnabled: true,
              });
            }}
          />
        );
      }

      // unknown type
      else {
        blocks.push(
          <div style={{ border: '1px solid #f00' }} key={block.id}>
            Unknown Block Type {block.id}
          </div>
        );
      }

    }


    return (
      <div className='pi3lesson pi3narrow'>

        <div className="pi3lessonHeading">
          <h3>
            <div style={{ display: 'inline-block', padding: '3px 10px 0 0' }}>
              {getLessonTypeIconMonochrome(lesson.type)}
            </div>
            {lesson.name}
          </h3>
        </div>

        {blocks}

        <div style={{ margin: '20px 0 40px 0', display: 'flex', justifyContent: 'flex-end' }}>
          <button className='pi3buttonSmall' disabled={this.state.completeEnabled ? undefined : 'disabled'} onClick={this.completeLessonAndNavigate}>Complete and Continue</button>
        </div>

      </div>
    );

  }
}

export default LearnerChatflow;

