
// import 'whatwg-fetch';
import 'cross-fetch/polyfill'; // TODO pi3upgrade

/* global __HOST__ */
// const rootUrl = __HOST__; // webpack populates this

const CACHE_TTL = 30000; // 30sec

import { sleep } from './helpers';


async function uploadCourseImage(file, onUploadProgress = null, onUploadComplete = null) {
  try {
    console.log('[AjaxAdmin] Uplading image...');
    let url = `/api/v3/admin/upload/image`;

    const formData = new FormData();
    formData.append('image', file);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);

    xhr.upload.addEventListener('progress', event => {
      const percentComplete = Math.round((event.loaded / event.total) * 100); 
      if (onUploadProgress) {
        onUploadProgress(percentComplete);
      }
    });

    xhr.addEventListener('load', () => {
      if (xhr.status === 200) {
        console.log('[AjaxAdmin] Image uploaded successfully.', xhr.responseText);
        let jsonResponse = JSON.parse(xhr.responseText);
        if (onUploadComplete) {
          onUploadComplete(jsonResponse);
        }
      } else {
        console.error('[AjaxAdmin] Error uploading image.', xhr.statusText);
      }
    });

    xhr.send(formData);

  } catch(e) {
    console.error(`[AjaxAdmin] Error uploading image.`, e);
    throw e;
  }
}

async function uploadCourseVideo(videoFile, subtitleFile, onUploadProgress = null, onUploadComplete = null) {
  try {
    console.log('[AjaxAdmin] Uplading video...');
    let url = `/api/v3/admin/upload/video`;

    const formData = new FormData();
    formData.append('video', videoFile);
    formData.append('subtitle', subtitleFile);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);

    xhr.upload.addEventListener('progress', event => {
      const percentComplete = Math.round((event.loaded / event.total) * 100); 
      if (onUploadProgress) {
        onUploadProgress(percentComplete);
      }
    });

    xhr.addEventListener('load', () => {
      if (xhr.status === 200) {
        let jsonResponse = JSON.parse(xhr.responseText);
        console.log('[AjaxAdmin] Video uploaded successfully.', jsonResponse);
        if (onUploadComplete) {
          onUploadComplete(jsonResponse);
        }
      } else {
        console.error('[AjaxAdmin] Error uploading video.', xhr.statusText);
      }
    });

    xhr.send(formData);

  }
  catch(e) {
    console.error(`[AjaxAdmin] Error uploading video.`, e);
    throw e;
  }
}

async function uploadCourseDocument(file, onUploadProgress = null, onUploadComplete = null) {
  try {
    console.log('[AjaxAdmin] Uplading document...');
    let url = `/api/v3/admin/upload/document`;

    const formData = new FormData();
    formData.append('document', file);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);

    xhr.upload.addEventListener('progress', event => {
      const percentComplete = Math.round((event.loaded / event.total) * 100); 
      if (onUploadProgress) {
        onUploadProgress(percentComplete);
      }
    });

    xhr.addEventListener('load', () => {
      if (xhr.status === 200) {
        console.log('[AjaxAdmin] Document uploaded successfully.', xhr.responseText);
        let jsonResponse = JSON.parse(xhr.responseText);
        if (onUploadComplete) {
          onUploadComplete(jsonResponse);
        }
      } else {
        console.error('[AjaxAdmin] Error uploading document.', xhr.statusText);
      }
    });

    xhr.send(formData);

  } catch(e) {
    console.error(`[AjaxAdmin] Error uploading document.`, e);
    throw e;
  }
}

async function uploadCourseBinary(file, onUploadProgress = null, onUploadComplete = null) {
  try {
    console.log('[AjaxAdmin] Uplading binary...');
    let url = `/api/v3/admin/upload/binary`;

    const formData = new FormData();
    formData.append('document', file);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);

    xhr.upload.addEventListener('progress', event => {
      const percentComplete = Math.round((event.loaded / event.total) * 100); 
      if (onUploadProgress) {
        onUploadProgress(percentComplete);
      }
    });

    xhr.addEventListener('load', () => {
      if (xhr.status === 200) {
        console.log('[AjaxAdmin] Binary uploaded successfully.', xhr.responseText);
        let jsonResponse = JSON.parse(xhr.responseText);
        if (onUploadComplete) {
          onUploadComplete(jsonResponse);
        }
      } else {
        console.error('[AjaxAdmin] Error uploading binary.', xhr.statusText);
      }
    });

    xhr.send(formData);

  } catch(e) {
    console.error(`[AjaxAdmin] Error uploading binary.`, e);
    throw e;
  }
}

async function reorderLessons(lessons) {
  try {
    console.log('[AjaxAdmin] Running reorderLessons()...', { lessons });
    let url = `/api/v3/admin/course/reorder-lessons`;
    let response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(lessons),
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in reorderLessons().');
      throw response;
    }
    let json = await response.json();
    return json;
  }
  catch(e) {
    console.error('[AjaxAdmin] Error running reorderLessons().', e);
    throw e;
  }
}

async function reorderSections(sectionIds) {
  try {
    console.log('[AjaxAdmin] Running reorderSections()...', { sectionIds });
    let url = `/api/v3/admin/course/reorder-sections`;
    let response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ sectionIds }),
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in reorderSections().');
      throw response;
    }
    let json = await response.json();
    return json;
  }
  catch(e) {
    console.error('[AjaxAdmin] Error running reorderSections().', e);
    throw e;
  }
}

async function getCompanyList() {
  try {
    console.log('[AjaxAdmin] Fetching company list...');
    let url = `/api/v3/admin/companies?fields=id,name,emailSuffixes,isVisible,disableEmailCampaigns`;

    let response = await fetch(url, {
      headers: {'Accept': 'application/json'},
      method: 'GET',
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error fetching company list.');
      throw response;
    }
    let json = await response.json();
    return json;

  } catch(e) {
    console.error('[AjaxAdmin] Error fetching company list.', e);
    throw e;
  }
}

let companiesCache = null;
let companiesCachedAt = null;
function getCompanyListCached(forceRefresh = false) {
  return new Promise((resolve, reject) => {
    // console.log(`Fetching company list... forceRefresh? ${forceRefresh}`);
    let now = new Date().getTime();
    // invalidate cache if too old
    if (companiesCache && companiesCachedAt !== null && companiesCachedAt + CACHE_TTL < now) {
      // console.log('found old cache, deleted.');
      companiesCache = null;
      companiesCachedAt = null;
    }
    // if no cache or started promises
    if (forceRefresh || !companiesCache) {
      // console.log('starting new promise...');
      companiesCache = new Promise(innerResolve => {
        getCompanyList().then(list => {
          companiesCache = list;
          companiesCachedAt = new Date().getTime();
          innerResolve(companiesCache);
        }).catch(e => {
          console.error('[AjaxAdmin] Error fetching company list', e);
          reject(e);
        });
      });
    } else {
      // console.log('fetching value from cache or passed ongoing promise.');
    }
    resolve(companiesCache);
  });
}

async function getCompany(companyId) {
  try {
    console.log('[AjaxAdmin] Fetching company...', { companyId });

    let url = `/api/v3/admin/company/${companyId}`;

    let response = await fetch(url, {
      headers: {'Accept': 'application/json'},
      method: 'GET',
    });

    if (!response.ok) {
      console.error('[AjaxAdmin] Error fetching company.');
      throw response;
    }
    let json = await response.json();
    return json;

  } catch(e) {
    console.error('[AjaxAdmin] Error fetching company.', e);
    throw e;
  }
}

async function getCompanyDetailed(companyId) {
  try {
    console.log('[AjaxAdmin] Fetching company details...', { companyId });

    let url = `/api/v3/admin/company/${companyId}/details`;

    let response = await fetch(url, {
      headers: {'Accept': 'application/json'},
      method: 'GET',
    });

    if (!response.ok) {
      console.error('[AjaxAdmin] Error fetching company details.');
      throw response;
    }
    let json = await response.json();
    return json;


  } catch(e) {
    console.error('[AjaxAdmin] Error fetching company details.', e);
    throw e;
  }
}

async function getManagerAnalytics3(planIds, format = null) {
  try {
    console.log('[AjaxAdmin] Fetching manager analytics (v3)...', { planIds, format });
    let url = `/api/v3/reporting/manager?planIds=${planIds}`;

    if (format !== null) {
      url += `&format=${format}`;
    }
    let data = await fetch(url, {
      headers: {'Accept': 'application/json'},
      method: 'GET'
    });
    if (!data.ok) {
      // TODO add 'unauthorized' recovery here if necessary?
      console.error('[AjaxAdmin] Error fetching manager analytics (v3).', data);
      throw data;
    }
    if (format === null || format === 'json') {
      let json = await data.json();
      //console.log('Fetched manager analytics (v3) data.', json);
      return json;
    }
    else if (format === 'xlsx') {
      let payload = await data.text();
      return payload;
    }
  }
  catch(e) {
    console.error('[AjaxAdmin] Error fetching manager analytics (v3).', e);
    throw e;
  }
}

async function getCourses() {
  try {
    console.log('[AjaxAdmin] Running getCourses()...');
    let url = `/api/v3/admin/courses`;
    let response = await fetch(url, {
      headers: {'Accept': 'application/json'},
      method: 'GET',
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in getCourses().');
      throw response;
    }
    let json = await response.json();
    return json;
  }
  catch(e) {
    console.error('[AjaxAdmin] Error in getCourses().', e);
    throw e;
  }
}


let coursesCache = null;
let coursesCachedAt = null;
function getCoursesCached(forceRefresh = false) {
  return new Promise((resolve, reject) => {
    // console.log(`Fetching course list... forceRefresh? ${forceRefresh}`);
    let now = new Date().getTime();
    // invalidate cache if too old
    if (coursesCache && coursesCachedAt !== null && coursesCachedAt + CACHE_TTL < now) {
      // console.log('found old cache, deleted.');
      coursesCache = null;
      coursesCachedAt = null;
    }
    // if no cache or started promises
    if (forceRefresh || !coursesCache) {
      // console.log('starting new promise...');
      coursesCache = new Promise(innerResolve => {
        getCourses().then(list => {
          coursesCache = list;
          coursesCachedAt = new Date().getTime();
          innerResolve(coursesCache);
        }).catch(e => {
          console.error('[AjaxAdmin] Error fetching course list', e);
          reject(e);
        });
      });
    } else {
      // console.log('fetching value from cache or passed ongoing promise.');
    }
    resolve(coursesCache);
  });
}



async function getCourse(courseId) { 
  try {
    console.log('[AjaxAdmin] Running getCourse()...');
    let url = `/api/v3/admin/course/${courseId}`;
    let response = await fetch(url, {
      headers: {'Accept': 'application/json'},
      method: 'GET',
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in getCourse().');
      throw response;
    }
    let json = await response.json();
    return json;
  }
  catch(e) {
    console.error('[AjaxAdmin] Error in getCourse().', e);
    throw e;
  }
}

async function createCourse(data) {
  try {
    console.log('[AjaxAdmin] Running createCourse()...', data);
    let url = `/api/v3/admin/course`;
    let response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in createCourse().');
      throw response;
    }
    let json = await response.json();
    return json;
  }
  catch(e) {
    console.error('[AjaxAdmin] Error running createCourse().', e);
    throw e;
  }
}

async function updateCourse(courseId, data) {
  try {
    console.log('[AjaxAdmin] Running updateCourse()...', data);
    let url = `/api/v3/admin/course/${courseId}`;
    let response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in updateCourse().');
      throw response;
    }
    let json = await response.json();
    return json;
  }
  catch(e) {
    console.error('[AjaxAdmin] Error running updateCourse().', e);
    throw e;
  }
}

async function deleteCourse(id) { 
  try {
    console.log('[AjaxAdmin] Running deleteCourse()...', { id });
    let url = `/api/v3/admin/course/${id}`;

    let response = await fetch(url, {
      headers: {'Accept': 'application/json'},
      method: 'DELETE',
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in deleteCourse().');
      throw response;
    }
    let json = await response.json();
    return json;
  }
  catch(e) {
    console.error('[AjaxAdmin] Error in deleteCourse().', e);
    throw e;
  }
}

async function cloneCourse(id) {
  try {
    console.log('[AjaxAdmin] Running cloneCourse()...', { id });
    let url = `/api/v3/admin/course/${id}/clone`;
    let response = await fetch(url, {
      headers: {'Accept': 'application/json'},
      method: 'POST',
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in cloneCourse().');
      throw response;
    }
    let json = await response.json();
    return json;
  }
  catch(e) {
    console.error('[AjaxAdmin] Error in cloneCourse().', e);
    throw e;
  }
}

async function createCourseSection(data) {
  try {
    console.log('[AjaxAdmin] Running createCourseSection()...', data);
    let url = `/api/v3/admin/course/section`;
    let response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in createCourseSection().');
      throw response;
    }
    let json = await response.json();
    return json;
  }
  catch(e) {
    console.error('[AjaxAdmin] Error running createCourseSection().', e);
    throw e;
  }
}

async function updateCourseSection(courseSectionId, data) {
  try {
    console.log('[AjaxAdmin] Running updateCourseSection()...', data);
    let url = `/api/v3/admin/course/section/${courseSectionId}`;
    let response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in updateCourseSection().');
      throw response;
    }
    let json = await response.json();
    return json;
  }
  catch(e) {
    console.error('[AjaxAdmin] Error running updateCourseSection().', e);
    throw e;
  }
}

async function deleteCourseSection(id) {
  try {
    console.log('[AjaxAdmin] Running deleteCourseSection()...', { id });
    let url = `/api/v3/admin/section/${id}`;

    let response = await fetch(url, {
      headers: {'Accept': 'application/json'},
      method: 'DELETE',
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in deleteCourseSection().');
      throw response;
    }
    let json = await response.json();
    return json;
  }
  catch(e) {
    console.error('[AjaxAdmin] Error in deleteCourseSection().', e);
    throw e;
  }
}

async function createCourseLesson(data) {
  try {
    console.log('[AjaxAdmin] Running createCourseLesson()...', data);
    let url = `/api/v3/admin/course/lesson`;
    let response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in createCourseLesson().');
      throw response;
    }
    let json = await response.json();
    return json;
  }
  catch(e) {
    console.error('[AjaxAdmin] Error running createCourseLesson().', e);
    throw e;
  }
}

async function updateCourseLesson(courseLessonId, data) {
  try {
    console.log('[AjaxAdmin] Running updateCourseLesson()...', data);
    let url = `/api/v3/admin/course/lesson/${courseLessonId}`;
    let response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in updateCourseLesson().');
      throw response;
    }
    let json = await response.json();
    return json;
  }
  catch(e) {
    console.error('[AjaxAdmin] Error running updateCourseLesson().', e);
    throw e;
  }
}

async function deleteCourseLesson(id) {
  try {
    console.log('[AjaxAdmin] Running deleteCourseLesson()...', { id });
    let url = `/api/v3/admin/lesson/${id}`;

    let response = await fetch(url, {
      headers: {'Accept': 'application/json'},
      method: 'DELETE',
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in deleteCourseLesson().');
      throw response;
    }
    let json = await response.json();
    return json;
  }
  catch(e) {
    console.error('[AjaxAdmin] Error in deleteCourseLesson().', e);
    throw e;
  }
}

async function getCourseLesson(id) {
  try {
    console.log('[AjaxAdmin] Running getCourseLesson()...', { id });
    let url = `/api/v3/admin/lesson/${id}`;

    let response = await fetch(url, {
      headers: {'Accept': 'application/json'},
      method: 'GET',
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in getCourseLesson().');
      throw response;
    }
    let json = await response.json();
    return json;

  }
  catch(e) {
    console.error('[AjaxAdmin] Error in getCourseLesson().', e);
    throw e;
  }
}

async function getCompanyListDetailed() { 
  try {
    console.log(`[AjaxAdmin] Running getCompanyListDetailed()...`);
    let url = `/api/v3/admin/companies-detailed`;

    let response = await fetch(url, {
      headers: {'Accept': 'application/json'},
      method: 'GET',
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in getCompanyListDetailed().');
      throw response;
    }
    let json = await response.json();
    return json;
  }
  catch(e) {
    console.error(`[AjaxAdmin] Error in getCompanyListDetailed().`, e);
  }
}

async function createCompany(data) {
  try {
    console.log('[AjaxAdmin] Running createCompany()...', data);
    let url = `/api/v3/admin/company`;
    let response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in createCompany().');
      throw response;
    }
    let json = await response.json();
    return json;
  }
  catch(e) {
    console.error('[AjaxAdmin] Error running createCompany().', e);
    throw e;
  }
}

async function updateCompany(companyId, data) {
  try {
    console.log('[AjaxAdmin] Running updateCompany()...', data);
    let url = `/api/v3/admin/company/${companyId}`;
    let response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in updateCompany().');
      throw response;
    }
    let json = await response.json();
    return json;
  }
  catch(e) {
    console.error('[AjaxAdmin] Error running updateCompany().', e);
    throw e;
  }
}

async function deleteCompany(companyId) { 
  try {
    console.log(`[AjaxAdmin] Running deleteCompany()...`, { companyId });

    let url = `/api/v3/admin/company/${companyId}`;

    let response = await fetch(url, {
      headers: {'Accept': 'application/json'},
      method: 'DELETE',
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in deleteCompany().');
      throw response;
    }
    let json = await response.json();
    return json;
  }
  catch(e) {
    console.error(`[AjaxAdmin] Error in deleteCompany().`, e);
  }
}

async function getCompanyPlan(companyPlanId) {
  try {
    console.log('[AjaxAdmin] Fetching company plan...', { companyPlanId });

    let url = `/api/v3/admin/company-plan/${companyPlanId}`;

    let response = await fetch(url, {
      headers: {'Accept': 'application/json'},
      method: 'GET',
    });

    if (!response.ok) {
      console.error('[AjaxAdmin] Error fetching company plan.');
      throw response;
    }
    let json = await response.json();
    return json;

  } catch(e) {
    console.error('[AjaxAdmin] Error fetching company plan.', e);
    throw e;
  }
}

async function createCompanyPlan(data) {
  try {
    console.log('[AjaxAdmin] Running createCompanyPlan()...', data);
    let url = `/api/v3/admin/company-plan`;
    let response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in createCompanyPlan().');
      throw response;
    }
    let json = await response.json();
    return json;
  }
  catch(e) {
    console.error('[AjaxAdmin] Error running createCompanyPlan().', e);
    throw e;
  }
}

async function updateCompanyPlan(companyPlanId, data) {
  try {
    console.log('[AjaxAdmin] Running updateCompanyPlan()...', data);
    let url = `/api/v3/admin/company-plan/${companyPlanId}`;
    let response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in updateCompanyPlan().');
      throw response;
    }
    let json = await response.json();
    return json;
  }
  catch(e) {
    console.error('[AjaxAdmin] Error running updateCompanyPlan().', e);
    throw e;
  }
}

async function deleteCompanyPlan(companyPlanId) {
  try {
    console.log(`[AjaxAdmin] Running deleteCompanyPlan()...`, { companyPlanId });

    let url = `/api/v3/admin/company-plan/${companyPlanId}`;

    let response = await fetch(url, {
      headers: {'Accept': 'application/json'},
      method: 'DELETE',
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in deleteCompanyPlan().');
      throw response;
    }
    let json = await response.json();
    return json;
  }
  catch(e) {
    console.error(`[AjaxAdmin] Error in deleteCompanyPlan().`, e);
  }
}


async function unenrollCompanyPlan() { // LEGACY
  // TODO implement
}


async function getUser(userId) {
  try {
    console.log(`[AjaxAdmin] Fetching User...`, { userId });

    let url = `/api/v3/admin/user/${userId}`;

    let response = await fetch(url, {
      headers: {'Accept': 'application/json'},
      method: 'GET',
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in getUser().');
      throw response;
    }
    let json = await response.json();
    return json;

  }
  catch(e) {
    console.error('[AjaxAdmin] Error in getUser().', e);
    throw e;
  }
}

async function getUserCourseProgress(userId, courseId) {
  try {
    console.log(`[AjaxAdmin] Fetching User Course progress...`, { userId, courseId });

    let url = `/api/v3/admin/user/${userId}/progress/${courseId}`;

    let response = await fetch(url, {
      headers: {'Accept': 'application/json'},
      method: 'GET',
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in getUserCourseProgress().');
      throw response;
    }
    let json = await response.json();
    return json;

  }
  catch(e) {
    console.error('[AjaxAdmin] Error in getUserCourseProgress().', e);
    throw e;
  }
}


async function getUsers(companyId = 'null', companyPlanId = null, filter = '', offset = 0, limit = 50) {
  try {
    console.log(`[AjaxAdmin] Attempting to fetch User list...`, { companyId, companyPlanId, filter, offset, limit });

    let url = `/api/v3/admin/users?`; 

    if (companyId !== null) {
      url+= `companyId=${companyId}`;
    }
    if (companyPlanId !== null) {
      url += `&companyPlanId=${companyPlanId}`;
    }
    if (filter.length > 0) {
      url += `&filter=${filter}`
    }
    if (offset) {
      url += `&offset=${offset}`;
    }
    if (limit) {
      url += `&limit=${limit}`;
    }


    let response = await fetch(url, {
      headers: {'Accept': 'application/json'},
      method: 'GET',
    });

    if (!response.ok) {
      console.error('[AjaxAdmin] Error fetching company.');
      throw response;
    }

    let users = await response.json();
    // console.log('[AjaxAdmin] Fetched list of users.', { users });
    return users;

  } catch(e) {
    console.error('[AjaxAdmin] Error fetching User list.', { offset, limit });
    throw e;
  }
}

async function createUser(data) {
  try {
    console.log('[AjaxAdmin] Running createUser()...', data);
    let url = `/api/v3/admin/user`;
    let response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in createUser().');
      throw response;
    }
    let json = await response.json();
    return json;
  }
  catch(e) {
    console.error('[AjaxAdmin] Error running createUser().', e);
    throw e;
  }
}

async function updateUser(userId, data) {
  try {
    console.log('[AjaxAdmin] Running updateUser()...', data);
    let url = `/api/v3/admin/user/${userId}`;
    let response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in updateUser().');
      throw response;
    }
    let json = await response.json();
    return json;
  }
  catch(e) {
    console.error('[AjaxAdmin] Error running updateUser().', e);
    throw e;
  }
}

async function deleteUser(userId) {
  try {
    console.log(`[AjaxAdmin] Running deleteUser()...`, { userId });

    let url = `/api/v3/admin/user/${userId}`;

    let response = await fetch(url, {
      headers: {'Accept': 'application/json'},
      method: 'DELETE',
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in deleteUser().');
      throw response;
    }
    let json = await response.json();
    return json;
  }
  catch(e) {
    console.error(`[AjaxAdmin] Error in deleteUser().`, e);
  }
}

async function moveUserToCompany(userId, companyId) {
  try {
    console.log(`[AjaxAdmin] Running moveUserToCompany()...`, { userId, companyId });

    let url = `/api/v3/admin/user/${userId}/move`;
    let response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
      body: JSON.stringify({ companyId }),
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in moveUserToCompany().');
      throw response;
    }
    let json = await response.json();
    return json;

  }
  catch(e) {
    console.error(`[AjaxAdmin] Error in moveUserToCompany().`, e);
  }
}

async function addUserToCompanyPlan(userId, companyPlanId, role = null, createEnrollments = false) {
  try {
    console.log(`[AjaxAdmin] Running addUserToCompanyPlan()...`, { userId, companyPlanId });

    let url = `/api/v3/admin/user/${userId}/add-plan`;
    let response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
      body: JSON.stringify({ companyPlanId, role, createEnrollments }),
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in addUserToCompanyPlan().');
      throw response;
    }
    let json = await response.json();
    return json;

  }
  catch(e) {
    console.error(`[AjaxAdmin] Error in addUserToCompanyPlan().`, e);
  }
}

async function removeUserFromCompanyPlan(userId, companyPlanId) {
  try {
    console.log(`[AjaxAdmin] Running removeUserFromCompanyPlan()...`, { userId, companyPlanId });

    let url = `/api/v3/admin/user/${userId}/remove-plan`;
    let response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
      body: JSON.stringify({ companyPlanId }),
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in removeUserFromCompanyPlan().');
      throw response;
    }
    let json = await response.json();
    return json;

  }
  catch(e) {
    console.error(`[AjaxAdmin] Error in removeUserFromCompanyPlan().`, e);
  }
}


async function searchUsers(term) {
  try {
    console.log(`[AjaxAdmin] Running searchUsers()...`, { term });

    let url = `/api/v3/admin/users/search?q=${term}`;

    let response = await fetch(url, {
      headers: {'Accept': 'application/json'},
      method: 'GET',
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in searchUsers().');
      throw response;
    }
    let json = await response.json();
    return json;

  }
  catch(e) {
    console.error(`[AjaxAdmin] Error in searchUsers().`, e);
  }
}


async function createEnrollment(data) {
  try {
    console.log('[AjaxAdmin] Running createEnrollment()...', data);
    let url = `/api/v3/admin/enrollment`;
    let response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in createEnrollment().');
      throw response;
    }
    let json = await response.json();
    return json;
  }
  catch(e) {
    console.error('[AjaxAdmin] Error running createEnrollment().', e);
    throw e;
  }
}

async function updateEnrollment(enrollmentId, data) {
  try {
    console.log('[AjaxAdmin] Running updateEnrollment()...', data);
    let url = `/api/v3/admin/enrollment/${enrollmentId}`;
    let response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in updateEnrollment().');
      throw response;
    }
    let json = await response.json();
    return json;
  }
  catch(e) {
    console.error('[AjaxAdmin] Error running updateEnrollment().', e);
    throw e;
  }
}

async function deleteEnrollment(id) {
  try {
    console.log('[AjaxAdmin] Running deleteEnrollment()...', { id });

    let url = `/api/v3/admin/enrollment/${id}`;

    let response = await fetch(url, {
      headers: {'Accept': 'application/json'},
      method: 'DELETE',
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in deleteEnrollment().');
      throw response;
    }
    let json = await response.json();
    return json;

  }
  catch(e) {
    console.error('[AjaxAdmin] Error running deleteEnrollment().', e);
    throw e;
  }
}

async function generateCertificate(userId, courseId, completedAt = null) {
  try {
    console.log('[AjaxAdmin] Running generateCertificate()...', { userId, courseId, completedAt });

    let url = `/api/v3/admin/certificate`;
    let response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ userId, courseId, completedAt }),
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in generateCertificate().');
      throw response;
    }
    let json = await response.json();
    return json;
  }
  catch(e) {
    console.error('[AjaxAdmin] Error running generateCertificate().', e);
    throw e;
  }
}

async function deleteCertificate(id) {
  try {
    console.log('[AjaxAdmin] Running deleteCertificate()...', { certificateId: id });

    let url = `/api/v3/admin/certificate/${id}`;

    let response = await fetch(url, {
      headers: {'Accept': 'application/json'},
      method: 'DELETE',
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in deleteCertificate().');
      throw response;
    }
    let json = await response.json();
    return json;

  }
  catch(e) {
    console.error('[AjaxAdmin] Error running deleteCertificate().', e);
    throw e;
  }
}

async function adminFormList() {
  try {
    console.log('[AjaxAdmin] Running adminFormList()...', { });

    let url = `/api/v3/admin/form/list`;

    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    if (!result.ok) {
      console.error('[Ajax] Error loading form list.');
      throw result;
    }
    let json = await result.json();
    return json;

  }
  catch(e) {
    console.error('[Ajax] Error in adminFormList()', { e });
    throw e;
  }
}

async function adminFormLoad(id) {
  try {
    console.log('[AjaxAdmin] Running adminFormLoad()...', { });

    let url = `/api/v3/admin/form?id=${encodeURIComponent(id)}`;

    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    if (!result.ok) {
      console.error('[Ajax] Error loading form.');
      throw result;
    }
    let json = await result.json();
    return json;

  }
  catch(e) {
    console.error('[Ajax] Error in adminFormLoad()', { e });
    throw e;
  }
}

async function adminFormCreate(data) {
  try {
    console.log('[AjaxAdmin] Running adminFormCreate()...', { });

    let url = `/api/v3/admin/form/create`;

    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    });
    if (!result.ok) {
      console.error('[Ajax] Error creating form.');
      throw result;
    }
    let json = await result.json();
    return json;

  }
  catch(e) {
    console.error('[Ajax] Error in adminFormCreate()', { e });
    throw e;
  }
}

async function adminFormUpdate(id, data) {
  try {
    console.log('[AjaxAdmin] Running adminFormUpdate()...', { });

    let url = `/api/v3/admin/form/update?id=${encodeURIComponent(id)}`;

    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    });
    if (!result.ok) {
      console.error('[Ajax] Error updating form.');
      throw result;
    }
    let json = await result.json();
    return json;

  }
  catch(e) {
    console.error('[Ajax] Error in adminFormUpdate()', { e });
    throw e;
  }
}

async function adminFormRecords(id) {
  try {
    console.log('[AjaxAdmin] Running adminFormRecords()...', { id });

    let url = `/api/v3/admin/form/records?id=${encodeURIComponent(id)}`;

    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    if (!result.ok) {
      console.error('[Ajax] Error loading form.');
      throw result;
    }
    let json = await result.json();
    return json;

  }
  catch(e) {
    console.error('[Ajax] Error in adminFormRecords()', { e });
    throw e;
  }
}

async function getFeedUsers(companyId = 'null', companyPlanId = null, offset = 0, limit = 50) {
  try {
    console.log(`[AjaxAdmin] Attempting to fetch Users Feed...`, { companyId, companyPlanId, offset, limit });
    let url = `/api/v3/admin/feed/users?`;
    if (companyId !== null) {
      url += `&companyId=${companyId}`;
    }
    if (companyPlanId !== null)
      url += `&companyPlanId=${companyPlanId}`;
    if (offset)
      url += `&offset=${offset}`;
    if (limit)
      url += `&limit=${limit}`;

    let response = await fetch(url, {
      headers: {'Accept': 'application/json'},
      method: 'GET',
    });

    if (!response.ok) {
      console.error('[AjaxAdmin] Error fetching Users Feed.');
      throw response;
    }

    let users = await response.json();
    return users;

  } catch(e) {
    console.error('[AjaxAdmin] Error fetching Users Feed.', { offset, limit });
    throw e;
  }
}


async function getFeedActivities(userId = null, offset = 0, limit = 50) {
  try {
    console.log(`[AjaxAdmin] Attempting to fetch Activities Feed...`, { userId, offset, limit });
    let url = `/api/v3/admin/feed/activities?`;
    if (userId !== null)
      url += `&userId=${userId}`;
    if (offset)
      url += `&offset=${offset}`;
    if (limit)
      url += `&limit=${limit}`;

    let response = await fetch(url, {
      headers: {'Accept': 'application/json'},
      method: 'GET',
    });

    if (!response.ok) {
      console.error('[AjaxAdmin] Error fetching Activities Feed.');
      throw response;
    }

    let users = await response.json();
    return users;

  } catch(e) {
    console.error('[AjaxAdmin] Error fetching Activities Feed.', { offset, limit });
    throw e;
  }
}

// course author
async function authorGetCourses() {
  try {
    console.log('[AjaxAdmin] Running authorGetCourses()...');
    let url = `/api/v3/author/courses`;
    let response = await fetch(url, {
      headers: {'Accept': 'application/json'},
      method: 'GET',
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in authorGetCourses().');
      throw response;
    }
    let json = await response.json();
    return json;
  }
  catch(e) {
    console.error('[AjaxAdmin] Error in authorGetCourses().', e);
    throw e;
  }
}

async function authorUpdateCourse(courseId, data) {
  try {
    console.log('[AjaxAdmin] Running authorUpdateCourse()...', data);
    let url = `/api/v3/author/course/${courseId}`;
    let response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      console.error('[AjaxAdmin] Error in authorUpdateCourse().');
      throw response;
    }
    let json = await response.json();
    return json;
  }
  catch(e) {
    console.error('[AjaxAdmin] Error running authorUpdateCourse().', e);
    throw e;
  }
}
 
export default {

  // company management
  getCompanyList,
  getCompanyListCached,
  getCompanyListDetailed,
  getCompany,
  getCompanyDetailed,
  createCompany,
  updateCompany,
  deleteCompany,
  getCompanyPlan,
  createCompanyPlan,
  updateCompanyPlan,
  deleteCompanyPlan,

  // reporting
  getManagerAnalytics3,

  // course management
  getCourses,
  getCoursesCached,
  getCourse,
  createCourse,
  updateCourse,
  deleteCourse,
  cloneCourse,
  createCourseSection,
  updateCourseSection,
  deleteCourseSection,
  getCourseLesson,
  createCourseLesson,
  updateCourseLesson,
  deleteCourseLesson,
  uploadCourseImage,
  uploadCourseVideo,
  uploadCourseDocument,
  uploadCourseBinary,
  reorderLessons,
  reorderSections,

  // user management
  getUser,
  getUserCourseProgress,
  getUsers,
  createUser,
  updateUser,
  deleteUser, // in progress
  moveUserToCompany, // in progress
  addUserToCompanyPlan,
  removeUserFromCompanyPlan,
  searchUsers,

  createEnrollment,
  updateEnrollment,
  deleteEnrollment,

  generateCertificate,
  deleteCertificate,

  // forms
  adminFormList,
  adminFormLoad,
  adminFormCreate,
  adminFormUpdate,
  adminFormRecords,

  // admin feed
  getFeedUsers,
  getFeedActivities,

  // legacy
  unenrollCompanyPlan,

  // course author
  authorGetCourses,
  authorUpdateCourse,
};
