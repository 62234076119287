
'use strict';

import enums from '../../src/pi3-frontend/enums.js';
import React from 'react';
import {postLearnerActivity} from '../../src/pi3-frontend/ajax.js';

// Access token used to prevent cross-site scripting attacks.
//
// @see https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage#security_concerns
const iFrameToken = (() => {
  const bits = 144;
  const bytes = Math.ceil(bits / Math.log2(36));
  const randomBytes = new Uint8Array(bytes);
  // `window.crypto` is a native browser function that needs to be accessed
  // without `window` when used from NodeJS.
  crypto.getRandomValues(randomBytes);
  // `window.btoa` is a native browser function that needs to be accessed
  // without `window` when used from NodeJS.
  return btoa([...randomBytes].reduce((c, b) => c + String.fromCharCode(b), ''))
    .replace(/[\+\/\=]/g, '').toLowerCase().substring(0, bytes)
  ;
})();


const beforeUnloadHandler = (e) => {
  // @see https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
  e.preventDefault();
  e.returnValue = true;
};

class CourseBlockChatflow extends React.Component {
  constructor(props) {
    super(props);
    window.addEventListener("message", async (e) => {
      try {
        const msg = JSON.parse(e.data);
        if (msg?.token != iFrameToken)
          return;
        if (msg.data.start) {
          if (!window.__hasBeforeUnload) {
            window.__hasBeforeUnload = true;
            window.addEventListener('beforeunload', beforeUnloadHandler);
          }
          this.props.onStart?.();
        }
        if (msg.data.complete) {
          if (window.__hasBeforeUnload) {
            window.__hasBeforeUnload = false;
            window.removeEventListener('beforeunload', beforeUnloadHandler);
          }
          this.props.onComplete?.();
          try {
            console.log('[CourseBlockChatflow] Posting lesson complete...', msg);
            await postLearnerActivity(
              enums.UserActivity.Type.ChatflowComplete,
              props.lesson.id,
              msg.data.summary.maxPoints,
              msg.data.summary.totalPoints,
            );
          } catch (err) {
            console.error('[CourseBlockChatflow] Error posting lesson complete', err);
          }
        }
        if (msg.data.report) {
          try {
            console.log('[CourseBlockChatflow] Posting report complete...', msg);
            await postLearnerActivity(
              enums.UserActivity.Type.ChatflowReport,
              props.lesson.id,
              undefined,
              undefined,
              {
                chatflowId: block.html,
                question: msg.data.question,
                answer: msg.data.answer,
                feedback: msg.data.feedback,
              },
            );
          } catch (err) {
            console.error('[CourseBlockChatflow] Error posting report complete', err);
          }
        }
      } catch (err) {}
    });
  }
  render() {
    let block = this.props.block;
    return (
      <div
        className='pi3courseChatflow'
      >
        <iframe
          className="tw:border-0 tw:w-full"
          style={{
            height: '500px',
          }}
          src={`${__CHATFLOW_API_URL__}/chatflow-pi/${encodeURIComponent(block.html)}?-iframe-token=${encodeURIComponent(iFrameToken)}`}
        />
      </div>
    );
  }
}

export default CourseBlockChatflow;
