
import {stringify as libYAMLStringify} from 'yaml';

export const yamlStringify = (value) => {
  return libYAMLStringify(
    value,
    undefined,
    {
      defaultKeyType: 'PLAIN',
      defaultStringType: 'QUOTE_DOUBLE',
    },
  );
};
