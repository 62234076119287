
// @see https://quillforms.com/
// @see https://github.com/quillforms/quillforms
// @see https://codesandbox.io/p/sandbox/quill-forms-conditional-blocks-rendering-0r9x2e
export const formDefinitionToQuillFormDefinition = (formDefinition) => {
  return {
    // By default `Quill Forms` display one input per page.
    // They call these inputs / pages `blocks`.
    blocks: formDefinition.pages.map((page, pageIndex) => {
      const pageBlocks = (() => {
        const blocks = [];
        for (const block of page.blocks) {
          if (block.component == 'Group') {
            const shuffle = (arr) => {
              const res = arr.slice();
              const rv = new Uint32Array(1);
              for (const i in arr) {
                crypto.getRandomValues(rv);
                const r = rv[0] % (parseInt(i) + 2);
                if (r == parseInt(i) + 1)
                  continue;
                [res[i], res[r]] = [res[r], res[i]];
              }
              return res;
            };
            for (const subBlock of ((block.props?.shuffle || block.shuffle) ? shuffle(block.blocks) : block.blocks))
              blocks.push(subBlock);
            continue;
          }
          blocks.push(block);
        }
        return blocks;
      })();
      const quillBlock = {
        id: page.id ?? `page_${pageIndex}`,
        name: 'group',
        attributes: {
          label: page.title ? page.title : ' ',
        },
        // To allow multiple inputs on the same page a Quill `group` block
        // needs to be used and then `innerBlocks` can be displayed on the same page.
        //
        // @see https://quillforms.com/forms/simple-contact-form/
        // @see https://codesandbox.io/p/sandbox/quill-forms-group-block-9nddyp
        innerBlocks: pageBlocks.map((block, blockIndex) => ({
          id: block.id ?? `answer_${pageIndex}_${blockIndex}`,
          name: (() => {
            const componentToQuillBlockNameMap = {
              'ChoicesInput': 'multiple-choice',
              'DropdownInput': 'dropdown',
              // To get a full page HTML a "welcome-screen" Quill block must be used
              // and it case only be used on the first page as there is a special case
              // using `WelcomeScreensWrapper` in Quill renderer.
              // @see node_modules/@quillforms/renderer-core/src/components/form-flow/index.tsx
              'HTML': pageIndex == 0 && pageBlocks.length == 1 ? 'welcome-screen' : 'html',
              'MultiLineTextInput': 'long-text',
              'OpinionScaleInput': 'opinion-scale',
              'SingleLineTextInput': 'short-text',
            };
            if (!componentToQuillBlockNameMap[block.component])
              throw new Error(`Unsupported component '${block.component}'.`);
            return componentToQuillBlockNameMap[block.component];
          })(),
          attributes: {
            // Used by all components
            label: block.props?.label,
            required: block.props?.required ?? false,
            placeholder: block.props?.placeholder ?? '',
            multiple: block.props?.multiple,

            // Used by 'ChoicesInput' and `DropdownInput`
            choices: (block.props?.choices ?? []).map(c => (typeof(c) == 'string') ? ({label: c, value: c}) : c),

            // Used by `HTML`
            [pageIndex == 0 && pageBlocks.length == 1 ? 'customHTML' : 'html']: block.props?.html ?? '',

            // MultiLineTextInput
            setMaxCharacters: (block.props?.maxCharacters ?? 0) > 0,
            maxCharacters: block.props?.maxCharacters,

            // Used by `OpinionScaleInput`
            start: block.props?.start,
            end: block.props?.end,
            leftLabel: block.props?.leftLabel,
            centerLabel: block.props?.centerLabel,
            rightLabel: block.props?.rightLabel,
            reverseValue: block.props?.reverseValue,
          },
        })),
      };
      return pageBlocks.length == 1 ? quillBlock.innerBlocks[0] : quillBlock;
    }),
  };
};
