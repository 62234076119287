
import React from 'react';

import {HTMLParser} from '@quillforms/renderer-core';
import {registerBlockType} from '@quillforms/blocks';

export const registerBlockTypeHTML = () => {
  registerBlockType('html', {
    name: 'html',
    supports: {
      editable: false,
      required: false,
      attachment: false,
      description: false,
      logic: false
    },
    attributes: {
      html: {
        type: 'string',
        default: '',
      },
    },
    display: (props) => {
      props.setVal('');
      props.setIsValid(true);
      props.setValidationErr(null);
      props.setIsAnswered(true);
      return (
        <>
          <div>
            <HTMLParser value={props.attributes.html ?? ''} />
          </div>
        </>
      );
    },
  });
};
