

// page view
function emitPageView(url, title) {
  if (window.dataLayer) {
    let e = {
      event: 'pageView',
      pageUrl: url,
      pageTitle: title,
    };
    window.dataLayer.push(e);
    console.log('Pushed pageView event.', e);
  }
  else {
    console.warn(`[Analytics] Can't find dataLayer.`);
  }
}

// individual purchase made via Stripe
function emitIndividualPurchaseComplete(amountUsd, stripeCheckoutSessionId, userEmail, items) {
  if (window.dataLayer) {
    let e = {
      event: 'individualPurchaseComplete',
      stripeCheckoutSessionId: stripeCheckoutSessionId,
      email: userEmail,

      value: amountUsd,
      currency: 'USD',
      transaction_id: stripeCheckoutSessionId,
      items,
    };
    window.dataLayer.push(e);
    console.log('Pushed individualPurchaseComplete event.', e);
  }
  else {
    console.warn(`[Analytics] Can't find dataLayer.`);
  }
}

function emitIndividualRepurchaseComplete(amountUsd, stripeCheckoutSessionId, email, user, items) {
  if (window.dataLayer) {
    let e = {
      event: 'individualRepurchaseComplete',
      stripeCheckoutSessionId: stripeCheckoutSessionId,
      email: email,
      user: user,

      value: amountUsd,
      currency: 'USD',
      transaction_id: stripeCheckoutSessionId,
      items,
    };
    window.dataLayer.push(e);
    console.log('Pushed individualPurchaseComplete event.', e);
  }
  else {
    console.warn(`[Analytics] Can't find dataLayer.`);
  }
}

// teams package purchased via Stripe
function emitTeamsPurchaseComplete(amountUsd, stripeCheckoutSessionId, email, emailStripe, licenseType, seats, items) {
  if (window.dataLayer) {
    let e = {
      event: 'teamsPurchaseComplete',
      stripeCheckoutSessionId: stripeCheckoutSessionId,
      email: email, // user email
      emailStripe: emailStripe,
      licenseType: licenseType,
      seats: seats,

      value: amountUsd,
      currency: 'USD',
      transaction_id: stripeCheckoutSessionId,
      items,
    };
    window.dataLayer.push(e);
    console.log('Pushed teamsPurchaseComplete event.', e);
  }
  else {
    console.warn(`[Analytics] Can't find dataLayer.`);
  }
}

// teams wire transfer invoice generated
function emitTeamsWireInvoiceGenerated(amountCents, email, licenseType, seats) {
  if (window.dataLayer) {
    let ev = {
      event: 'teamsWireInvoiceGenerated',
      price: amountCents / 100,
      email: email,
      licenseType: licenseType,
      seats: seats,
    };
    window.dataLayer.push(ev);
    console.log('Pushed teamsWireInvoiceGenerated event.', ev);
  }
  else {
    console.warn(`[Analytics] Can't find dataLayer.`);
  }
}


export {
  emitPageView,
  emitIndividualPurchaseComplete,
  emitIndividualRepurchaseComplete,
  emitTeamsPurchaseComplete,
  emitTeamsWireInvoiceGenerated,
};