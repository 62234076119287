'use strict';

import React from 'react';

import enums from './enums';
import { getCourseProgress } from './ajax';
import { getLessonTypeIcon, getStatusIcon, MenuCourseProgress } from './MenuTools';


function getItemClass(item) {
  if (item && item.isActive) {
    return 'pi3menuItem active';
  }
  return 'pi3menuItem';
}

class SideMenu extends React.Component {
  constructor(props) {
    super(props);
    if (props.data) {
      // console.log('[SideMenu] Constructing...');
      // server-side rendering
      this.state = {
        isOpen: false,
        data: this.props.data,
        progress: null,
      };
    }
    else {
      console.error('[SideMenu] Menu data not set neither through props nor global.');
    }
  }

  async componentDidMount() {
    try {
      console.log('[SideMenu] Loading course status...');


      let data = this.state.data;
      if (!data || !data.courseItems) return;
      let progress = await getCourseProgress(data.courseItems.id); 

      console.log('[SideMenu] Fetched course progress.', progress);

      for (let section of data.courseItems.sections) {
        for (let lesson of section.lessons) {
          let l = progress.lessons.find(i => i.lessonId === lesson.id);
          if (l.isCompleted) {
            lesson.status = enums.CourseLesson.Status.Read;
          }
          else if (l.isInProgress) {
            lesson.status = enums.CourseLesson.Status.Reading;
          }
        }
      }
      this.setState({ data, progress });

      // scoll into position
      const element = document.getElementById('sideMenuActive');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }


    }
    catch(e) {
      console.error('[Menu] Error in componentDidMount().', e);
    }
  }
  render() {

    let data = this.state.data;
    let progress = this.state.progress;

    // if logged out, show 'logged out' / legacy menu
    if (data.isAuthenticated === false) {
      return (
        <div>
          {/* <LegacyMenu /> */}
        </div>
      );
    }

    // console.log('[SideMenu] Gonna render now, this is my data', { data });

    let renderedMenuItems = data.items.map(i => {
      return (
        <a href={i.link} key={i.label}>
          <div className={getItemClass(i)}> 
            {i.label}
          </div>
        </a>
      );
    });

    // add course items
    let courseContainer = '';
    let courseProgressContainer = '';

    if (data.courseItems) {
      // console.log('[SideMenu] We have data.courseItems, loading course data into SideMenu.', data.courseItems);
      let courseItems = [];
      // create section items
      for (let s = 0; s < data.courseItems.sections.length; s++) {
        let section = data.courseItems.sections[s];
        let lessonItems = [];
        // create lesson items
        for (let l = 0; l < section.lessons.length; l++) {
          let lesson = section.lessons[l];

          let href = `/course/${data.courseItems.id}/lesson/${lesson.id}`;
          if (lesson.type === enums.CourseLesson.Type.Quiz) {
            href = `/course/${data.courseItems.id}/quiz/${lesson.id}`;
          }
          else if (lesson.type === enums.CourseLesson.Type.QuizPlus) {
            href = `/course/${data.courseItems.id}/quiz-plus/${lesson.id}`;
          }
          else if (lesson.type === enums.CourseLesson.Type.Chatflow) {
            href = `/course/${data.courseItems.id}/chatflow/${lesson.id}`;
          }

          if (this.props.adminMode) {
            // lesson editor links, rather than 'reader' links
            href = `/admin/lesson/${lesson.id}/editor`;
            if (lesson.type === enums.CourseLesson.Type.Quiz) {
              href = `/admin/quiz/${lesson.id}/editor`;
            }
            else if (lesson.type === enums.CourseLesson.Type.QuizPlus) {
              href = `/admin/quiz-plus/${lesson.id}/editor`;
            }
          }

          let lessonClassName = 'pi3menuCourseLesson';
          let statusIcon = getStatusIcon(lesson.status);
          if (lesson.isActive) {
            lessonClassName = 'pi3menuCourseLesson active';
            if (lesson.status === enums.CourseLesson.Status.Unread) {
              statusIcon = getStatusIcon(enums.CourseLesson.Status.Reading);
            }
          }

          if (lesson.isActive) {
            lessonItems.push(
              <a href={href} key={l}>
                <div className={lessonClassName} id='sideMenuActive'>
                  <div className="left">
                    {statusIcon}
                  </div>
                  <div className="separator"></div>
                  <div className="middle">
                    {getLessonTypeIcon(lesson.type)}
                  </div>
                  <div className="right">
                    {lesson.name}
                  </div>
                </div>
              </a>
            );
          }
          else {
            lessonItems.push(
              <a href={href} key={l}>
                <div className={lessonClassName}>
                  <div className="left">
                    {statusIcon}
                  </div>
                  <div className="separator"></div>
                  <div className="middle">
                    {getLessonTypeIcon(lesson.type)}
                  </div>
                  <div className="right">
                    {lesson.name}
                  </div>
                </div>
              </a>
            );
          }


        }
        let sectionItem = (
          <div key={section.id}>
            <div className='pi3menuCourseHeader'>
              {/* <span>Unit {s+1}</span>
              <br /> */}
              <span>{section.name}</span>
            </div>
            {lessonItems}
          </div>
        );
        courseItems.push(sectionItem);
      }
      courseContainer = (
        <div className='pi3menuCourseContainer'>
          {courseItems}
        </div>
      );
      if (progress) {
        courseProgressContainer = (
          <MenuCourseProgress progress={progress} course={data.courseItems} />
        );
      }

    }
    else {
      console.log('[SideMenu] We *do not* have data.courseItems.');
    }



    return (
      <div className="pi3sideMenu" href="#sidemenu">
        <nav>
          <div className="pi3menuPop">
            {renderedMenuItems}
            {courseContainer}
            {courseProgressContainer}
          </div>
        </nav>
      </div>
    );

  }
}

export default SideMenu;



