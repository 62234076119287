
import React from 'react';

import ajaxAdmin from '../../src/pi3-frontend/ajax-admin.js';
import {css} from 'emotion';
import {useFirstRender} from '../../src/utilities/React.js';
import {useStateAtom} from '../../src/utilities/React/UseStateAtom.js';
import {yamlStringify} from '../../src/utilities/yaml/yamlStringify.js';

export default function (props) {

  const formInfo = useStateAtom(null);
  const records = useStateAtom(null);
  const recordActive = useStateAtom(null);

  useFirstRender(async () => {
    formInfo((await ajaxAdmin.adminFormRecords(props.formId)).form);
    records(formInfo().records);
  });

  return (<>

    <div
      className={css`
        width: 100%;
        height: 100vh;
        font-size: 16px;
      `}
    >
      <div
        className={css`
          display: flex;
          width: 100%;
          height: 100%;
        `}
      >
        <div
          className={css`
            flex: none;
            width: 300px;
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding: 5px;
          `}
        >
          {records() && records().map((record) => (<>
            <div
              className={css`
                cursor: pointer;
                ${recordActive() == record.id ? 'text-decoration: underline;' : ''}
              `}
              onClick={(e) => {
                recordActive(record.id);
              }}
            >
              {(new Date(record.createdAt)).toUTCString()}
            </div>
          </>))}
        </div>
        <div>
          {records() && records().map((record) => (<>
            {recordActive() == record.id && (<>
              <div>
                <pre>
                  {/*yamlStringify(record.answers.map(a => ({...a, quillFormsData: undefined})))/**/}
                  {yamlStringify(record.answers.map(a => ({
                    question: a.props?.label ?? '',
                    answer: a.value,
                    metadata: a.metadata,
                  })))}
                </pre>
              </div>
            </>)}
          </>))}

        </div>
      </div>
    </div>

  </>);

};
