import React from 'react';

import { DateTime } from 'luxon';

import { shallowCopy } from './helpers';
import * as session from './session';
import { getUserInfo, getJoinInfo, joinCheck, joinTeam } from './ajax';
import validation from './validation';
import { FormCheckbox } from './FormElements';
import Modal from './Modal';


import LoadingCenter from './LoadingCenter';

class Join extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      state: 'loading', // 'loading', 

      // logged-in', 'not-found', 'too-early', 'too-late', 'seats-taken', 'ok', 

      // 'login' - user created/enrolled, start login flow
      // 'login-submitting' - sending confirmation code
      // 'login-error' - wrong confirmatino code, plz try again

      form: {
        email: '',
        nameFirst: '',
        nameLast: '',
        terms: false,
        marketing: false,

        confirmationCode: '',
      },

      validate: false,
      tree: null,

      modalIsOpenJoinedTooEarly: false, // modal for 'join too early'

    };
    this.merge = this.merge.bind(this);
    this.validate = this.validate.bind(this);
    this.submitJoin = this.submitJoin.bind(this);
    this.joinComplete = this.joinComplete.bind(this);

    // possible states:
    // 'loading' - loading user data
    // 'logged-in' - user is logged in (user needs to logout first to create new account)
    // 
    // endpoints:
    // POST /join (with name, email, terms, marketing)
    //  - response 1: email does not belong to company (please use only authorized email domains, not private email)
    //  - response 2: this company plan is over quota, please contact your team manager
    //  - response 3: (login email sent) please enter code to login / finish creating account

  }

  async componentDidMount() {
    try {
      console.log(`[Join] Running componentDidMount()...`);
      // fetch user info
      let user = await getUserInfo();
      // console.log(`[Join] Fetched user.`, { user });
  
      // fetch plan/company info
      let joinInfo = await getJoinInfo(window.magicLink);
      console.log(`[Join] Fetched Join info.`, { joinInfo });

      let isOpenTooEarly = this.state.isOpenTooEarly;
      if (joinInfo.result === 'too-early') {
        isOpenTooEarly = true;
      }

      this.setState({
        state: joinInfo.result,
        modalIsOpenJoinedTooEarly: joinInfo.result === 'too-early' ? true : false,
        plan: joinInfo.plan
      });

      if (user !== null) {
        console.log(`[Join] User already logged in, redirecting to courses...`);
        this.setState({ state: 'logged-in' });
        window.location.replace(`/courses`);
        return;
      }
    }
    catch(e) {
      console.error(`[Join] Error executing componentDidMount().`, e);
    }
  }

  merge(newData) {
    // console.log('[Profile] Running merge...', { newData });
    let form = shallowCopy(this.state.form, newData);
    this.setState({
      form,
      tree: this.validate(null, form),
    });
  }

  validate(attribute, newData) {
    // console.log('what are we merging?', { attribute, newData });
    let data = newData ? newData : this.state.form;

    // if attribute is provided, return empty string or CSS class to add to invalid field
    if (attribute) {
      if (this.state.validate) {
        // check whether attribute inside validation tree is valid, if not return 'error' CSS class
        if (this.state.tree && this.state.tree[attribute] && this.state.tree[attribute].length > 0) {
          return 'error';
        }
        return '';
      } else {
        return '';
      }
    } else {
      // generate validation tree
      let tree = {
        // name: validation.validateString(data.name) ? null : 'Name is required.',
        nameFirst: validation.validateString(data.nameFirst) ? null : 'Name is required.',
        nameLast: validation.validateString(data.nameLast) ? null : 'Name is required.',
        email: validation.validateEmail(data.email) ? null : 'Email is required.',
        emailDomain: validateEmailDomain(data.email, this.state.plan.company.emailSuffixes) ? null : 'Email domain not allowed.',
      };
      return tree;
    }
  }

  async joinComplete(email) {
    try {
      console.log('[Join] Join complete...');
      this.setState({ state: 'login', email: email });
    }
    catch(e) {
      console.error(`[Join] Error executing joinComplete().`, e);
    }
  }

  async submitJoin() { // deprecated
    try {
      
      console.log('[Join] Saving...');
      let tree = this.validate(null, this.state.form);
      let isHealthy = validation.isTreeHealthy(tree);
      this.setState({
        tree: tree,
        validate: true,
      });

      // return; // debug

      if (isHealthy) {
        let data = {
          // name: this.state.form.name,
          nameFirst: this.state.form.nameFirst,
          nameLast: this.state.form.nameLast,
          email: this.state.form.email,
          terms: this.state.form.terms,
          marketing: this.state.form.marketing
        };
        console.log('[Join] We have a valid form.', data);
        this.setState({ state: 'joining' });

        let result = await joinTeam(window.magicLink, data);

        console.log('[Join] Executed joinTeam()', result);

        if (result.success === true) {
          // user created, move on to login flow
          this.setState({ state: 'login' });
          return;
        }

        // user/email already exists, redirect to login page
        if(result.message === 'user-exists') {
          console.log(`[Join] User already exists, redirecting to login page...`);
          this.setState({ state: 'user-exists' });
          window.location.replace(`/login?e=${encodeURIComponent(this.state.form.email)}`);
          // window.location.replace(`/login?e=${this.state.form.email}`);
          return;
        }

      } else {
        console.log('[Join] We have an invalid form.', this.state.form);
      }

    }
    catch(e) {
      console.error(`[Join] Error executing submitJoin().`, e);
    }
  }

  

  render() {

    return (
      <div>

        {
          this.state.state === 'loading' &&
          <div>
            <LoadingCenter />
          </div>
        }

        {
          this.state.state === 'logged-in' && // user logged in, redirecting to /courses
          <div>
            <LoadingCenter />
          </div>
        }

        {
          this.state.state === 'not-found' && // magic link not found
          <SadCat>
            <h1>We can’t find your company.</h1>
            <br />
            <p>
              Double check the link with your company manager.
            </p>
            <p>
              If you’re having trouble, please contact us at <a href="mailto:info@productinstitute.com">info@productinstitute.com</a>
            </p>
            <br />
            <div>
              <a href="/">
                <button className="pi3button">Back to home page</button>
              </a>
            </div>
          </SadCat>
        }

        {
          this.state.state === 'too-late' && // company plan expired
          <SadCat>
            <h1>The company plan has expired.</h1>
            <br />
            <p>
              If you’re having trouble, please contact us at <a href="mailto:info@productinstitute.com">info@productinstitute.com</a>
            </p>
            <br />
            <div>
              <a href="/">
                <button className="pi3button">Back to home page</button>
              </a>
            </div>
          </SadCat>
        }

        {
          this.state.state === 'seats-taken' && // company plan full
          <SadCat>
            <h1>All the company seats have been taken.</h1>
            <br />
            <p>
              If you think this is an error, please contact your company manager.
            </p>
            <p>
              If you’re having trouble, please contact us at <a href="mailto:info@productinstitute.com">info@productinstitute.com</a>
            </p>
            <br />
            <div>
              <a href="/">
                <button className="pi3button">Back to home page</button>
              </a>
            </div>
          </SadCat>
        }

        {
          this.state.state === 'user-exists' && // user already exists, redirecting to login
          <div>
            <LoadingCenter />
          </div>
        }

        {
          this.state.state === 'login' && // success after joining Company Plan
          <div className='pi3form'>
            <JoinComputer plan={this.state.plan} />

            <div className='joinLine'></div>
            <br />
            <p>
              Welcome to Product Institute, you now have access to your team's courses!
              <br />
              Please proceed to the <a href={`/login?e=${encodeURIComponent(this.state.email)}`}>login</a> page to start exploring.
              <br />
              A one-time code will be sent to your email for access, no passwords are needed.
            </p>
            <br />
            <div className="formRow submitRowCenter">
              <a href={`/login?e=${this.state.email}`}>
                <button className="pi3button">Login</button>
              </a>
            </div>
          </div>
        }

        {
          (
            this.state.state === 'too-early' ||
            this.state.state === 'ok'
          ) &&
          <div className='pi3form'>

            {this.state.state === 'too-early' &&
              <Modal
                isOpen={this.state.modalIsOpenJoinedTooEarly}
                isEasyDismiss={false}
                onClose={() => this.setState({ modalIsOpenJoinedTooEarly: false })}>
                <h1>You’re Early!</h1>
                <p>
                  Your training hasn’t started yet, but don’t worry. We are working on it.
                </p>
                <p>
                  You can join now, but keep in mind you won't have access to any courses until {DateTime.fromISO(this.state.plan.startsAt).toFormat('LLLL d yyyy')}.
                </p>
                <p>
                  Reach out to your company manager for more details.
                </p>
                <div className='pi3modalButtonRow'>
                  <div
                    className='pi3pillButton'
                    onClick={() => this.setState({ modalIsOpenJoinedTooEarly: false })}>
                    Continue
                  </div>
                </div>
              </Modal>
            }

            <JoinForm magicLink={window.magicLink} plan={this.state.plan} onComplete={this.joinComplete} />

          </div>
        }

      </div>
    );

  }
}



function validateEmailDomain(email, domains) {
  let isValidDomain = false;
  if (domains && domains.length > 0) {
    for (let domain of domains) {
      if(email.indexOf(`@${domain}`) > -1) {
        isValidDomain = true;
        break;
      }
    }
  }
  else {
    isValidDomain = true;
  }
  return isValidDomain;
}

function JoinComputer(props) {
  return (
    <div className='joinComputer'>
      {/* <img src='/static/images/kompjutor.jpg' /> */}
      {/* <h1>Welcome <span>{props.plan.company.name}</span> to Product Institute!</h1> */}
      <h1>Welcome {props.plan.company.name} to Product Institute!</h1>
      {props.plan && props.plan.logo &&
        <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0 20px 0' }}>
          <img src={props.plan.logo.url} style={{ width: '200px' }} alt={props.plan.name} />
        </div>
      }
    </div>
  );
}



function SadCat(props) {
  return (
    <main className="pi3sadCat">
      <img src="/static/images/404.svg" alt="Sad cat" />
      <div>
        {props.children}
      </div>
    </main>
  );
}



export default Join;



// <JoinForm magicLink='' plan={} onComplete={} />
class JoinForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 'form-email', // 'form-email', 'form-join', 'loading', 
      form: {
        email: '',
        nameFirst: '',
        nameLast: '',
        terms: false,
        marketing: false,
      },
      validateEmailForm: false,
      validateJoinForm: false,
      treeEmailForm: null,
      treeJoinForm: null,
    };
    this.mergeEmailForm = this.mergeEmailForm.bind(this);
    this.mergeJoinForm = this.mergeJoinForm.bind(this);
    this.validateEmailForm = this.validateEmailForm.bind(this);
    this.validateJoinForm = this.validateJoinForm.bind(this);
    this.submitEmailCheck = this.submitEmailCheck.bind(this);
    this.submitJoin = this.submitJoin.bind(this);
  }

  mergeEmailForm(newData) {
    let form = shallowCopy(this.state.form, newData);
    this.setState({
      form,
      treeEmailForm: this.validateEmailForm(null, form),
    });
  }

  mergeJoinForm(newData) {
    let form = shallowCopy(this.state.form, newData);
    this.setState({
      form,
      treeJoinForm: this.validateJoinForm(null, form),
    });
  }

  validateEmailForm(attribute, newData) {
    let data = newData ? newData : this.state.form;
    // if attribute is provided, return empty string or CSS class to add to invalid field
    if (attribute) {
      if (this.state.validateEmailForm) {
        // check whether attribute inside validation tree is valid, if not return 'error' CSS class
        if (this.state.treeEmailForm && this.state.treeEmailForm[attribute] && this.state.treeEmailForm[attribute].length > 0) {
          return 'error';
        }
        return '';
      } else {
        return '';
      }
    } else {
      // generate validation tree
      let tree = {
        email: validation.validateEmail(data.email) ? null : 'Email is required.',
        emailDomain: validateEmailDomain(data.email, this.props.plan.company.emailSuffixes) ? null : 'Email domain not allowed.',
      };
      return tree;
    }
  }

  validateJoinForm(attribute, newData) {
    let data = newData ? newData : this.state.form;
    // if attribute is provided, return empty string or CSS class to add to invalid field
    if (attribute) {
      if (this.state.validateJoinForm) {
        // check whether attribute inside validation tree is valid, if not return 'error' CSS class
        if (this.state.treeJoinForm && this.state.treeJoinForm[attribute] && this.state.treeJoinForm[attribute].length > 0) {
          return 'error';
        }
        return '';
      } else {
        return '';
      }
    } else {
      // generate validation tree
      let tree = {
        nameFirst: validation.validateString(data.nameFirst) ? null : 'Name is required.',
        nameLast: validation.validateString(data.nameLast) ? null : 'Name is required.',
        email: validation.validateEmail(data.email) ? null : 'Email is required.',
        emailDomain: validateEmailDomain(data.email, this.props.plan.company.emailSuffixes) ? null : 'Email domain not allowed.',
      };
      return tree;
    }
  }

  async submitEmailCheck() {
    try {
      console.log('[Join] Checking email...');
      let treeEmailForm = this.validateEmailForm(null, this.state.form);
      let isHealthy = validation.isTreeHealthy(treeEmailForm);
      this.setState({
        treeEmailForm: treeEmailForm,
        validateEmailForm: true,
      });

      if (isHealthy) {
        let data = {
          email: this.state.form.email,
        };
        console.log('[Join] We have a valid Email form.', data);
        this.setState({ state: 'loading' });

        let result = await joinCheck(this.props.magicLink, data);

        console.log('[Join] Executed joinTeam()', result);

        if (result.success === true) {
          // user not, move on to register flow
          this.setState({ stage: 'form-join' });
          return;
        }

        else if (result.success === false) {
          console.log(`[Join] User already exists, redirecting to login page...`);
          this.setState({ state: 'loading' });
          window.location.replace(`/login?e=${encodeURIComponent(this.state.form.email)}`);
          return;
        }

      } else {
        console.log('[Join] We have an invalid Email form.', treeEmailForm);
      }

    }
    catch(e) {
      console.error(`[Join] Error executing submitEmailCheck().`, e);
    }
  }

  async submitJoin() {
    try {
      console.log('[Join] Submitting join request...');
      let treeJoinForm = this.validateJoinForm(null, this.state.form);
      let isHealthy = validation.isTreeHealthy(treeJoinForm);
      this.setState({
        treeJoinForm: treeJoinForm,
        validateJoinForm: true,
      });
      if (isHealthy) {
        let data = {
          nameFirst: this.state.form.nameFirst,
          nameLast: this.state.form.nameLast,
          email: this.state.form.email,
          terms: this.state.form.terms,
          marketing: this.state.form.marketing
        };
        console.log('[Join] We have a valid Join form.', data);
        this.setState({ state: 'joining' });

        let result = await joinTeam(this.props.magicLink, data);

        console.log('[Join] Executed joinTeam()', result);

        if (result.success === true) {
          // user created, move on to login flow
          this.props.onComplete(this.state.form.email);
          return;
        }

        // this should never happen
        if(result.message === 'user-exists') {
          console.log(`[Join] User already exists, redirecting to login page...`);
          this.setState({ state: 'loading' });
          window.location.replace(`/login?e=${encodeURIComponent(this.state.form.email)}`);
          return;
        }

      } else {
        console.log('[Join] We have an invalid Join form.', this.state.form);
      }

    }
    catch(e) {
      console.error(`[Join] Error executing submitJoin().`, e);
    }
  }

  render() {

    if (this.state.stage === 'loading') {
      <div>
        <LoadingCenter />
      </div>
    }

    if (this.state.stage === 'form-email') {
      return (
        <div>
          <JoinComputer plan={this.props.plan} />
  
          <h2>Create an account</h2>
          <div className='joinLine'></div>

          <br />

          <div className="formRow">
            <label htmlFor="input-message">Company Email Address</label>
            <input
              type="text"
              id='input-email'
              name='email'
              value={this.state.form.email}
              onChange={ev => this.mergeEmailForm({ email: ev.target.value })}
              className={` ${this.validateEmailForm('email')} ${this.validateEmailForm('emailDomain')}`} 
            />
          </div>

          {
            this.state.validateEmailForm && (this.state.treeEmailForm.email || this.state.treeEmailForm.emailDomain) &&
            <div>
              <div className='validationRow'>
                <img src='/static/images/icon-warning.svg' />
                {/* Sorry, the email must be a {this.state.plan.company.name} email address. */}
                Please use your company email to register.
              </div>
            </div>
          }

          <div className="submitRowCenter">
            <button className='pi3button' onClick={this.submitEmailCheck}>Continue</button>
            <br />
            <p>Have an account? <a href='/login'>Login instead</a></p>
          </div>

        </div>
      );
    }

    if (this.state.stage === 'form-join') {
      return (
        <div>
          <JoinComputer plan={this.props.plan} />

          <h2>Create an account</h2>
          <div className='joinLine'></div>

          <br />

          <div className="formRow">
            <label htmlFor="input-message">Company Email Address</label>
            <input
              type="text"
              id='input-email'
              name='email'
              value={this.state.form.email}
              onChange={ev => this.mergeEmailForm({ email: ev.target.value })}
              className={` ${this.validateEmailForm('email')} ${this.validateEmailForm('emailDomain')}`}
              disabled
            />
          </div>

          {
            this.state.validateEmailForm && (this.state.treeEmailForm.email || this.state.treeEmailForm.emailDomain) &&
            <div>
              <div className='validationRow'>
                <img src='/static/images/icon-warning.svg' />
                {/* Sorry, the email must be a {this.state.plan.company.name} email address. */}
                Please use your company email to register.
              </div>
            </div>
          }

          <div className="formRow">
            <label htmlFor="input-message">First Name</label>
            <input
              type="text"
              id='input-name'
              name='name-first'
              value={this.state.form.nameFirst}
              onChange={ev => this.mergeJoinForm({ nameFirst: ev.target.value })}
              className={` ${this.validateJoinForm('nameFirst')}`} 
            />
          </div>

          <div className="formRow">
            <label htmlFor="input-message">Last Name</label>
            <input
              type="text"
              id='input-name-last'
              name='name-last'
              value={this.state.form.nameLast}
              onChange={ev => this.mergeJoinForm({ nameLast: ev.target.value })}
              className={` ${this.validateJoinForm('nameLast')}`} 
            />
          </div>

          {this.props.plan.isTermsRequired &&
            <div className="formRow">
              <FormCheckbox
                isChecked={this.state.form.terms}
                isDisabled={false}
                label='I agree to the Terms and Conditions'
                onChange={terms => this.mergeJoinForm({ terms })}
              />
            </div>
          }

          {this.props.plan.isMarketingChoiceRequired && 
            <div className="formRow">
              <FormCheckbox
                isChecked={this.state.form.marketing}
                isDisabled={false}
                label='I agree to receive emails from Product Institute'
                onChange={marketing => this.mergeJoinForm({ marketing })}
              />
            </div>
          }

          <br />

          <div className="submitRowCenter">

            {
              this.props.plan.isTermsRequired && this.state.form.terms &&
              <button className='pi3button' onClick={this.submitJoin}>Create Account</button>
            }

            {
              this.props.plan.isTermsRequired && !this.state.form.terms &&
              <button className='pi3button' disabled>Create Account</button>
            }

            {
              !this.props.plan.isTermsRequired &&
              <button className='pi3button' onClick={this.submitJoin}>Create Account</button>
            }

            <br />
            <p>Have an account? <a href='/login'>Login instead</a></p>
          </div>

        </div>

      );
    }



  }

}