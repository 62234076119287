
export const formDefinitionFillIds = (formDefinition) => {
  const result = {
    pages: [],
  };
  for (const pageIndex in formDefinition?.pages ?? []) {
    const page = formDefinition.pages[pageIndex];
    const resultPage = {
      id: page.id ?? `page_${pageIndex}`,
      ...page,
      blocks: [],
    };
    for (const blockIndex in page.blocks) {
      const block = page.blocks[blockIndex];
      const resultBlock = {
        id: block.id ?? `answer_${pageIndex}_${blockIndex}`,
        ...block,
        blocks: [],
      };
      if (block.component == 'Group') {
        for (const subBlockIndex in block.blocks) {
          const subBlock = block.blocks[subBlockIndex];
          resultBlock.blocks.push({
            id: subBlock.id ?? `answer_${pageIndex}_${blockIndex}_${subBlockIndex}`,
            ...subBlock,
          });
        }
      }
      resultPage.blocks.push(resultBlock);
    }
    result.pages.push(resultPage);
  }
  return result;
};
